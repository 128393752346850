import {connect} from 'react-redux';
import CommentList from './commentList';
import '../../stylesheets/comments/comments-list.scss'

class UnapprovedList extends CommentList{
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            approved: 0
        }
    }
}

const mapStateToProps = (state) => {
    return {config: state.config}
}

export default connect(mapStateToProps, null)(UnapprovedList);
