import React, {Component} from 'react';
import {notification} from 'antd';
import {createImageList, setCurrentPage, setLoading} from '../../store/actions/images';
import axios from 'axios';

class ImageUploader extends Component {
    fileCheck = (file) => {
        if(file.size > 3145728)
        {
            notification.error({
                message: 'Dosyanız yüklenemedi!',
                description: <span><i>{file.name}</i> adlı dosyanız fazla büyük. En çok 3mb olabilir!</span>
            });
            return false;
        }
        else if(!['image/jpeg', 'image/png', 'image/svg+xml'].includes(file.type))
        {
            notification.error({
                message: 'Dosyanız yüklenemedi!',
                description: <span><i>{file.name}</i> adlı dosyanızın uzantısı geçerisiz. Yalnızca .jpg, .jpeg, .png ve .svg uzantılı dosyalar kabul edilebilir!</span>
            });
            return false;
        }
        else{
            return true;
        }
    }
    firstDownload = (page = 1) => {
        this.props.dispatch(setLoading(true));
        axios({
            url: this.props.config.cdnUrl + 'images/getAll',
            method: 'POST',
            data: {
                token: this.props.config.token, page
            }
        }).then(r => {
            if(r.data.status === 200){
                if(r.data.success){
                    this.props.dispatch(createImageList(r.data['totalImageCount']));
                    this.props.dispatch(setCurrentPage(page, r.data['images'].map((img, index) => {
                        return {
                            uid: index.toString(),
                            url: img
                        }
                    })));
                    setTimeout(() => {this.props.dispatch(setLoading(false));}, 500);
                }else{
                    this.failedToLoadResources();
                }
            }else{
                this.failedToLoadResources();
            }
        })
    }
    failedToLoadResources = () => {
        notification.error({ message: 'Bir sorunla karşılaşıldı!', description: 'Kaynaklar teknik bir sorundan dolayı yüklenemedi!'});
    }
}
export default ImageUploader;
