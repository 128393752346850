import Modal from 'antd/es/modal/Modal';
import {message, Tabs, Upload} from 'antd';
import ImageList from './imageList';
import {setPopup} from '../../store/actions/images';
import {connect} from 'react-redux';
import {InboxOutlined} from '@ant-design/icons';
import ImageUploader from './imageUploader';
const { TabPane } = Tabs;
const { Dragger } = Upload;

class SelectImage extends ImageUploader{
    state = {};
    copyToClipboard = str => {
        try{
            navigator.clipboard.writeText(str).then(r => r);
        }catch (e) {
            const el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    };
    selectImg = (file) => {
        this.copyToClipboard(file.url);
        message.success('Fotoğraf URL\'si panoya başarıyla kopyalandı.').then(r => r);
        this.props.dispatch(setPopup({open: false}));
    }
    uploadModalOnChange = (info) => {
        const { status } = info.file;
        if (status === 'uploading') {
            message.loading({content: <small><i>{info.file.name}</i> adlı dosyanız yükleniyor...</small>, key: info.file.uid, duration: 30}).then(r =>r);
        }
        if (status === 'done') {
            const res = JSON.parse(info.file.xhr.response);
            this.copyToClipboard(this.props.config.cdnUrl + 'images/get?img=' + res.images[0]);
            message.success({content: <small><i>{info.file.name}</i> adlı dosyanız başarıyla yüklendi ve URL'si panoya kopyalandı.</small>, key: info.file.uid, duration: 4}).then(r =>r);
            this.firstDownload(this.props.images.gallery.currentPage);
            this.props.dispatch(setPopup({open: false}));
        } else if (status === 'error') {
            message.error({content: <small><i>{info.file.name}</i> adlı dosyanız yüklenemedi!</small>, key: info.file.uid, duration: 4}).then(r =>r);
        }
    }
    render() {
        return (
            <Modal
                zIndex={9999}
                title={'Fotoğraf seç'}
                centered
                visible={this.props.images.popup.open}
                width={1000}
                footer={[]}
                onCancel={() => {
                    this.props.dispatch(setPopup({open: false}));
                }}
            >
                <Tabs
                    className={'image-selector'}
                    defaultActiveKey={'1'}
                    type={'card'}
                    size={100}
                    style={{
                        marginTop: '-1rem',
                        height: '25rem'
                    }}
                >
                    <TabPane tab={'Galeriden seç'} key={'1'} className={'image-selector-image-list'}>
                        <ImageList selectImageFnc={this.selectImg}/>
                    </TabPane>
                    <TabPane tab={'Fotoğraf yükle'} key={2} className={'image-selector-dragger'}>
                        <Dragger
                            className={'real-dragger'}
                            multiple={true}
                            action={this.props.config.cdnUrl + 'images/set'}
                            onChange={this.uploadModalOnChange}
                            beforeUpload={this.fileCheck}
                            showUploadList={false}
                            method={'POST'}
                            data={{token: this.props.config.token}}
                        >
                            <p className={'ant-upload-drag-icon'}>
                                <InboxOutlined />
                            </p>
                            <p className={'ant-upload-text'}>
                                Dosyalarınız yüklemek için bu alana tıklayın veya sürükleyin.
                            </p>
                            <p className={'ant-upload-hint'}>
                                Yalnızca .jpeg .jpg ve .png uzantıları kabul edilebilir ve dosyalarınız 3mb'den büyük olamaz.
                            </p>
                        </Dragger>
                    </TabPane>
                </Tabs>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.config,
        images: state.images
    }
}
const mapDispatchToProps = (dispatch) => ({
    setPopup,
    dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectImage);
