import {
    SET_PAGE,
    CREATE_IMAGE_LIST, SET_LOADING, SET_POPUP
} from '../types';

export const setCurrentPage = (page, images) => {
    setTimeout(() => {
        const previewButtons = document.querySelectorAll('.ant-upload-list-item-actions a, .ant-upload-list-item-actions button');
        Array.from(previewButtons).forEach(elem => {
            elem.removeAttribute('title');
        });
    },500);
    return {
        type: SET_PAGE, page, images
    };
};

export const setLoading = (loading) => {
    return {
        type: SET_LOADING,
        payload: loading
    };
};

export const createImageList = (count) => {
    const images = [];
    for (let i = 0; i < count; i++) {
        images.push(null);
    }
    return {
        type: CREATE_IMAGE_LIST,
        payload: images
    };
};

export const setPopup = (popup) => {
    return {
        type: SET_POPUP,
        payload: popup
    };
};