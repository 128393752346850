import {createStore, combineReducers} from 'redux';
import ImagesReducer from './reducers/images';
import ConfigReducer from './reducers/config';

const reducers = combineReducers({
    config: ConfigReducer,
    images: ImagesReducer
});

const store = createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;