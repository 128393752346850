import React, {Component} from 'react';
import { Pie } from '@ant-design/charts';
import {notification, PageHeader, Select, Table} from 'antd';
import {ClockCircleOutlined, EyeOutlined, FireOutlined, TeamOutlined} from '@ant-design/icons';
import AnimatedNumber from 'animated-number-react';
import axios from 'axios';
import {connect} from 'react-redux';
import '../../stylesheets/statistics/statistics.scss';

const {Option} = Select;

const twoDigits = (d) => {
    if(0 <= d && d < 10) return '0' + d.toString();
    if(-10 < d && d < 0) return '-0' + (-1*d).toString();
    return d.toString();
}
const toMysqlFormat = (date) => {
    return date.getUTCFullYear() + "-" + twoDigits(1 + date.getUTCMonth()) + "-" + twoDigits(date.getUTCDate());
};
const getDate = (day) => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - day);
}

class Statistics extends Component {
    state = {
        pagesColumn: [
            {
                title: 'Sayfa başlığı',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: 'Görüntülenme',
                dataIndex: 'view',
                key: 'view',
            },
            {
                title: 'Ortalama oturum süresi',
                dataIndex: 'averageSessionTime',
                key: 'averageSessionTime',
            }
        ],
        statistics: {
            realTime: 0,
            user: 0,
            view: 0,
            averageSessionTime: 0,
            devices: [],
            browsers: [],
            os: [],
            pages: [],
            city: [],
            lang: []
        }
    };
    calculateTime = (s) => {
        const minute = Math.floor(Math.floor(s)/60);
        const second = Math.floor(s)%60;
        return [minute === 0 ? false : minute, second];
    }
    createPie = (type) => {
        let text;
        if(type === 'devices'){
            text = 'Cihazlar'
        } else if(type === 'browsers'){
            text = 'Tarayıcılar'
        } else if(type === 'os'){
            text = 'İşletim Sistemi'
        } else if(type === 'city'){
            text = 'Şehirler'
        } else if(type === 'lang'){
            text = 'Diller'
        }
        const data = [...this.state.statistics[type]].map(datum => {
            return {
                title: datum.title,
                amount: parseInt(datum.amount)
            };
        })
        let config = {
            appendPadding: 10,
            data: data,
            angleField: 'amount',
            colorField: 'title',
            radius: 0.9,
            label: { type: 'outer' },
            interactions: [{ type: 'element-active' }],
        };
        return (<div className={'pie'}>
            <div className={'pie-title'}>
                <h4>{text}</h4>
            </div>
            <Pie {...config}/>
        </div>);
    }
    getStatistics = (firstTime, secondTime) => {
        axios({
            url: this.props.config.cdnUrl + 'statistics/get',
            method: 'POST',
            data: {
                token: this.props.config.token,
                firstTime, secondTime
            }
        }).then(res => {
            console.log(this.props.config);
            console.log(res);
            if(res.data.status === 200){
                if(res.data.success){
                    this.setState({
                        statistics: res.data.statistics
                    })
                }else{
                    notification.error({
                        message: 'Kaynaklar yüklenemedi!',
                        description: 'Teknik bir sorundan dolayı kaynaklar yüklenemedi!',
                    });
                }
            }else{
                notification.error({
                    message: 'Kaynaklar yüklenemedi!',
                    description: 'Teknik bir sorundan dolayı kaynaklar yüklenemedi!',
                });
            }
        })
            .catch(() => {
                notification.error({
                    message: 'Kaynaklar yüklenemedi!',
                    description: 'Teknik bir sorundan dolayı kaynaklar yüklenemedi!',
                });
            })
    }
    changeStatistics = (time) => {
        switch (time) {
            case 'last-week':
                this.getStatistics(toMysqlFormat(getDate(7)), toMysqlFormat(new Date()));
                break;
            case 'last-month':
                this.getStatistics(toMysqlFormat(getDate(30)), toMysqlFormat(new Date()));
                break;
            case 'last-3-month':
                this.getStatistics(toMysqlFormat(getDate(90)), toMysqlFormat(new Date()));
                break;
            case 'last-6-month':
                this.getStatistics(toMysqlFormat(getDate(180)), toMysqlFormat(new Date()));
                break;
            case 'last-year':
                this.getStatistics(toMysqlFormat(getDate(365)), toMysqlFormat(new Date()));
                break;
            case 'every-time':
                this.getStatistics(toMysqlFormat(getDate(9999)), toMysqlFormat(new Date()));
                break;
            default:
                break;
        }
    }
    componentDidMount() {
        setTimeout(() => {
            this.getStatistics(toMysqlFormat(getDate(7)), toMysqlFormat(new Date()));
        }, 100);
    }

    render() {
        const time = this.calculateTime(this.state.statistics.averageSessionTime);
        return (
            <div className={'statistics'}>
                <PageHeader
                    className={'page-header'}
                    title={'İstatistikler'}
                    extra={[
                        <Select defaultValue={'last-week'} style={{ width: 150 }} onChange={r => {
                            this.changeStatistics(r)
                        }} key={'select'}>
                            <Option value={'last-week'}>Son bir hafta</Option>
                            <Option value={'last-month'}>Son bir ay</Option>
                            <Option value={'last-3-month'}>Son 3 ay</Option>
                            <Option value={'last-6-month'}>Son 6 ay</Option>
                            <Option value={'last-year'}>Son 1 yıl</Option>
                            <Option value={'every-time'}>Tüm zamanlar</Option>
                        </Select>
                    ]}
                />
                <div className={'page-content'}>
                    <div className={'statistics-header'}>
                        <div className={'real-time box'}>
                            <div className={'icon'}>
                                <FireOutlined/>
                            </div>
                            <div className={'box-content'}>
                                <div className={'number'}>
                                    <AnimatedNumber
                                        className={'animated'}
                                        value={this.state.statistics.realTime}
                                        formatValue={value => Number(value).toFixed(0)}
                                    />
                                    <span className={'text'}>kullanıcı</span>
                                </div>
                                <div className={'title'}>
                                    Son 30 dakika içindeki kullanıcıların sayısı
                                </div>
                            </div>
                        </div>
                        <div className={'view box'}>
                            <div className={'icon'}>
                                <EyeOutlined/>
                            </div>
                            <div className={'box-content'}>
                                <div className={'number'}>
                                    <AnimatedNumber
                                        className={'animated'}
                                        value={this.state.statistics.view}
                                        formatValue={value => Number(value).toFixed(0)}
                                    />
                                    <span className={'text'}>görüntülenme</span>
                                </div>
                                <div className={'title'}>
                                    Toplam görüntülenme sayısı
                                </div>
                            </div>
                        </div>
                        <div className={'user box'}>
                            <div className={'icon'}>
                                <TeamOutlined/>
                            </div>
                            <div className={'box-content'}>
                                <div className={'number'}>
                                    <AnimatedNumber
                                        className={'animated'}
                                        value={this.state.statistics.user}
                                        formatValue={value => Number(value).toFixed(0)}
                                    />
                                    <span className={'text'}>kullanıcı</span>
                                </div>
                                <div className={'title'}>
                                    Toplam kullanıcı sayısı
                                </div>
                            </div>
                        </div>
                        <div className={'session-time box'}>
                            <div className={'icon'}>
                                <ClockCircleOutlined/>
                            </div>
                            <div className={'box-content'}>
                                <div className={'number'}>
                                    {time[0] && <span>
                                        <AnimatedNumber
                                            className={'animated'}
                                            value={time[0]}
                                            formatValue={value => Number(value).toFixed(0)}
                                        />
                                        <span className={'text'}>dk</span>
                                    </span>}
                                    {time[1] && <span>
                                        <AnimatedNumber
                                            className={'animated'}
                                            value={time[1]}
                                            formatValue={value => Number(value).toFixed(0)}
                                        />
                                        <span className={'text'}>sn</span>
                                    </span>}
                                </div>
                                <div className={'title'}>
                                    Ortalama oturum süresi
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'statistics-body'}>
                        <Table
                            columns={this.state.pagesColumn}
                            dataSource={this.state.statistics.pages.map(page => {
                                const time = this.calculateTime(Math.floor(parseInt(page['avgSessionTime'])));
                                return {
                                    key: Math.random(),
                                    title: page['title'],
                                    view: page['amount'],
                                    averageSessionTime: (!time[0] ? '' : (time[0] + ' dk ')) + time[1] + ' sn'
                                };
                            })}
                            size={'small'}
                        />
                        <div className={'charts'}>
                            {this.createPie('devices')}
                            {this.createPie('browsers')}
                            {this.createPie('os')}
                        </div>
                        <div className={'charts'}>
                            {this.createPie('city')}
                            {this.createPie('lang')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {config: state.config}
}
export default connect(mapStateToProps, null)(Statistics);
