import {SET_CONFIG} from '../types';

const defaultState = {
    token: 'token',
    cdnUrl: 'https://cdn.ankaratibbiyeliler.org.tr/',
    mainUrl: 'https://ankaratibbiyeliler.org.tr/',
    editComponent: {
        type: null,
        component: null
    },
    componentTypes: {
        title: 'Başlık',
        button: 'Buton',
        bigCard: 'Büyük kart',
        fileList: 'Dosyalar',
        gallery: 'Galeri',
        breadcrumb: 'Gezinti menüsü',
        image: 'Görsel',
        shareBar: 'İçeriği paylaş kutusu',
        contentCorner: 'İçerik köşesi',
        contentListing: 'İçerik listeleme',
        cards: 'Kartlar',
        person: 'Kişi',
        text: 'Metin',
        pageInfoBox: 'Sayfa bilgisi kutusu',
        slider: 'Slayt',
        lastAdded: 'Son eklenen içerikler',
        lastComments: 'Son yorumlar',
        calendar: 'Takvim',
        alertBox: 'Uyarı kutusu',
        comments: 'Yorumlar',
        embedYoutube: 'Youtube videosu'
        /*recommendedContents: 'Önerilen içerikler',*/
    },
};

const ConfigReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_CONFIG:
            return {...state, [action.key]: action.payload};
        default:
            return state;
    }
};

export default ConfigReducer;