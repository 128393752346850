import {Button, notification, PageHeader, Popover, Select} from 'antd';
import PageComponents from '../common-use/pageComponents';
import {connect} from 'react-redux';
import '../../stylesheets/page-components/page-components.scss';
import '../../stylesheets/page-components/page-components-creator.scss';
import {InfoCircleTwoTone, PictureOutlined} from '@ant-design/icons';
import {setPopup} from '../../store/actions/images';
import axios from 'axios';

const { Option } = Select;

class PageComponentsCreator extends PageComponents {

    saveComponent = () => {
        const check = this.componentPreCheck();
        if(check[0]){
            this.setState({ loading: true });
            axios({
                url: this.props.config.cdnUrl + 'components/set',
                method: 'POST',
                data: {
                    token: this.props.config.token,
                    component: JSON.stringify(check[1])
                }
            })
                .then(res => {
                    if(res.data.status === 200){
                        console.log(res.data);
                        if(res.data.success){
                            notification.success({
                                message: 'Bileşen başarıyla kaydedildi!',
                                description: 'Bileşen kaydetme işlemi başarıyla gerçekleştirildi'
                            });
                            this.setState({ loading: false });
                            this.resetComponentState();
                        }else{
                            this.setState({ loading: false });
                            notification.error({
                                message: 'Bileşen kaydedilemedi!',
                                description: 'Bu bileşen ismi zaten kullanılıyor'
                            });
                        }
                    }else{
                        this.setState({ loading: false });
                        notification.error({
                            message: 'Bileşen kaydedilemedi!',
                            description: 'Teknik bir sorundan dolayı bileşen kaydedilemedi'
                        });
                    }
                })
                .catch(() => {
                    this.setState({ loading: false });
                    notification.error({
                        message: 'Bileşen kaydedilemedi!',
                        description: 'Teknik bir sorundan dolayı bileşen kaydedilemedi'
                    });
                })
        }else{
            notification.warning({
                message: 'Geçersiz bileşen',
                description: check[1]
            });
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.resetComponentState();
            this.getPersons();
            this.getContentListingTitles();
        }, 100);
    }

    render() {
        return (
            <div className={'page-components-creator'}>
                <PageHeader
                    className={'page-header'}
                    title={'Yeni Bileşen Ekle'}
                />
                <div className={'page-content'}>
                    <div className={'page-components-header'}>
                        <div style={{flex: '1'}}/>
                        <Popover
                            content={
                            <div
                                style={{ width: 'min(300px,90vw)' }}
                            >
                                <small>
                                    Sayfanıza buradaki bileşen tiplerinden daha farklı tipteki bileşenleri de ekleyebilirsiniz.
                                    Burada olmayan bileşen tipleri <b>içeriği değişmeyen</b> bileşenlerdir.
                                    Sayfa şablonu oluştururken sayfanıza <b>içeriği değişmeyen</b> bileşen tiplerinden de ekleyebilirsiniz.
                                </small>
                            </div>
                        }>
                            <InfoCircleTwoTone style={{marginLeft: '1rem'}}/>
                        </Popover>
                        <Select
                            value={this.state.info.type}
                            style={{ width: '180px' }}
                            onChange={e => this.setComponentProperty('info','type',e)}
                        >
                            <Option value={'title'}>Başlık</Option>
                            <Option value={null}>Bileşen Tipi Seç</Option>
                            <Option value={'bigCard'}>Büyük Kart</Option>
                            <Option value={'button'}>Buton</Option>
                            <Option value={'fileList'}>Dosyalar</Option>
                            <Option value={'gallery'}>Galeri</Option>
                            <Option value={'breadcrumb'}>Gezinti Menüsü</Option>
                            <Option value={'contentCorner'}>İçerik Köşesi</Option>
                            <Option value={'contentListing'}>İçerik Listeleme</Option>
                            <Option value={'cards'}>Kartlar</Option>
                            <Option value={'person'}>Kişi</Option>
                            <Option value={'text'}>Metin</Option>
                            <Option value={'alertBox'}>Uyarı Kutusu</Option>
                            <Option value={'pageInfoBox'}>Sayfa Bilgisi Kutusu</Option>
                            <Option value={'slider'}>Slayt</Option>
                            <Option value={'calendar'}>Takvim</Option>
                            <Option value={'embedYoutube'}>Youtube Videosu</Option>
                        </Select>
                        <Button
                            type={'primary'}
                            onClick={this.saveComponent}
                            loading={this.state.loading}
                        >
                            Bileşeni Kaydet
                        </Button>
                    </div>
                    <div className={'component-edit-area'}>
                        {this.createComponentInfo()}
                        {this.createComponent(this.state.info.type)}
                    </div>
                </div>
                <Button
                    className={'select-image-button'}
                    icon={<PictureOutlined/>}
                    onClick={() => this.props.dispatch(setPopup({open: true}))}
                >
                    Görsel Seç
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.config
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPopup,
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(PageComponentsCreator);
