import {Component} from 'react';
import 'antd/dist/antd.css';
import '../../stylesheets/main/main.scss';
import NavigationMenu from './navigationMenu';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Error from '../error/error';
import Home from '../home/home';
import Gallery from '../gallery/gallery';
import SelectImage from '../common-use/selectImage';
import PageComponentsCreator from '../page-components/pageComponentsCreator';
import ApprovedList from '../comments/approvedList';
import UnapprovedList from '../comments/unapprovedList';
import Statistics from '../statistics/statistics';
import PageComponentsList from '../page-components/pageComponentsList';
import Settings from '../settings/settings';
import NewScheme from '../pages/newScheme';
import SchemeList from '../pages/schemeList';
import NewPage from '../pages/newPage';
import PageList from '../pages/pageList';
import Redirects from '../redirects/Redirects';
import {connect} from 'react-redux';
import {setConfig} from '../../store/actions/config';


const auth_token = document.cookie.split(';').map(i => {
    return {
        'key': i.trim(' ').split('=')[0],
        'value': i.trim(' ').split('=')[1]
    }
}).filter(i => i.key === 'auth_token')[0]

class App extends Component {
    componentDidMount() {
        this.props.dispatch(setConfig({
            key: 'token',
            value: auth_token['value']
        }));
    }
    render() {
        return (
            <div className={'app'}>
                <BrowserRouter>
                    <NavigationMenu/>
                    <div className={'content'}>
                            <Switch>
                                <Route path={'/'} exact component={Home}/>
                                <Route path={'/galeri'} exact component={Gallery}/>
                                <Route path={'/yorumlar/onaylanmislar'} exact component={ApprovedList}/>
                                <Route path={'/yorumlar/onaylanmamislar'} exact component={UnapprovedList}/>
                                <Route path={'/sayfa-bilesenleri/sayfa-bilesenleri-listesi'} exact component={PageComponentsList}/>
                                <Route path={'/sayfa-bilesenleri/yeni-bilesen-ekle'} exact component={PageComponentsCreator}/>
                                <Route path={'/istatistikler'} exact component={Statistics}/>
                                <Route path={'/ayarlar'} exact component={Settings}/>
                                <Route path={'/sayfalar/sablon-listesi'} exact component={SchemeList}/>
                                <Route path={'/sayfalar/yeni-sablon-ekle'} exact component={NewScheme}/>
                                <Route path={'/sayfalar/yeni-sayfa-ekle'} exact component={NewPage}/>
                                <Route path={'/sayfalar/sayfa-listesi'} exact component={PageList}/>
                                <Route path={'/yonlendirmeler'} exact component={Redirects}/>
                                <Route component={Error}/>
                            </Switch>
                    </div>
                    <SelectImage/>
                </BrowserRouter>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    setConfig,
    dispatch
});
export default connect(null, mapDispatchToProps)(App);
