import React, {Component} from 'react';
import {Button, Checkbox, DatePicker, Dropdown, Input, message, notification, Select, Transfer, Upload} from 'antd';
import {TwitterPicker} from 'react-color';
import {CKEditor} from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import moment from 'moment';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import {CaretDownOutlined, CaretUpOutlined, DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import axios from 'axios';

const {Option} = Select;
const {TextArea} = Input;

class PageComponents extends Component {
    defaultState = {
        editor: null,
        persons: [],
        contentListingList: [],
        loading: false,
        info: {
            title: `bilesen-${Math.floor(Math.random() * 100000).toString()}`,
            type: null
        },
        title: {
            value: null,
            color: '0,0,0',
            level: '1'
        },
        text: {
            data: '<p></p>'
        },
        bigCard: {
            backgroundColor1: '0,159,214',
            backgroundColor2: '72,181,140',
            fontColor: '255,255,255',
            value: null,
            url: null,
            blank: false,
            radius: false
        },
        person: {
            name: null,
            bio: null,
            ppUrl: null,
            url: null,
            last3content: false,
            showBio: false
        },
        embedYoutube: {
            url: null
        },
        alertBox: {
            type: 'success',
            title: null,
            content: null,
            icon: false
        },
        calendar: {
            list: [
                {
                    key: Math.random(),
                    date: moment(Date.now()),
                    title: null,
                    description: null,
                    URL: null
                }
            ]
        },
        contentCorner: {
            title: null,
            list: [
                {
                    key: Math.random(),
                    url: null,
                    text: null
                }
            ]
        },
        cards: {
            list: [
                {
                    key: Math.random(),
                    color1: '0,159,214',
                    color2: '72,181,140',
                    date: Date.now(),
                    title: null,
                    image: null,
                    content: null,
                    URL: null
                }
            ]
        },
        slider: {
            auto: false,
            list: [
                {
                    key: Math.random(),
                    backgroundColor: '255,255,255',
                    fontColor: '0,0,0',
                    image: null,
                    title: null,
                    URL: null,
                    position: 'bottomCenter'
                }
            ]
        },
        breadcrumb: {
            list: [
                {
                    key: Math.random(),
                    title: null,
                    URL: null
                }
            ]
        },
        pageInfoBox: {
            person: 'kisi-sec',
            showDate: false,
            showCommentCount: false,
        },
        image: {
            url: null,
            caption: null
        },
        comments: {},
        recommendedContents: {},
        shareBar: {},
        lastAdded: {},
        lastComments: {},
        personBox: {
            person: 'kisi-sec'
        },
        button: {
            type: 'normal',
            text: null,
            url: null
        },
        gallery: {
            list: [
                {
                    key: Math.random(),
                    url: null
                }
            ]
        },
        fileList: {
            list: [
                {
                    key: Math.random(),
                    title: null,
                    name: null,
                    url: null
                }
            ]
        },
        contentListing: {
            list: []
        },
    };

    state = { ...this.defaultState };

    getPersons = (func=null) => {
        axios({
            url: this.props.config.cdnUrl + 'components/getPersons',
            method: 'POST',
            data: {
                token: this.props.config.token
            }
        }).then(res => {
            if(res.data.status === 200){
                if(res.data.success){
                    const persons = res.data['persons'];
                    if(func !== null){
                        func(persons);
                    }else{
                        this.setState({ persons });
                    }
                }
            }
        })
    }

    getContentListingTitles = () => {
        axios({
            url: this.props.config.cdnUrl + 'pages/getContentListingTitles',
            method: 'POST',
            data: {
                token: this.props.config.token
            }
        }).then(res => {
            if(res.data.status === 200){
                if(res.data.success){
                    this.setState({ contentListingList: res.data['titles'] });
                }
            }
        })
    }

    checkURL = (url) => {
        try{
            new URL(url);
            return true;
        }catch (e) {
            return false;
        }
    }

    componentPreCheck = () => {
        if( this.state.info.type === null ){
            return [false, 'Bileşen tipi seçmelisiniz!'];
        }
        else if( this.state.info.title === null ){
            return [false, 'Bileşeninize benzersiz bir isim girmelisiniz!'];
        }
        else if( this.state.info.title.trim() === '' ){
            return [false, 'Bileşeninize benzersiz bir isim girmelisiniz!'];
        }
        else if( this.state.info.title.trim().length > 30){
            return [false, 'Bileşeninizin ismi 30 karakterden uzun olamaz!'];
        }
        else if( this.state.info.title.trim().length < 3){
            return [false, 'Bileşeninizin ismi 3 karakterden kısa olamaz!'];
        }
        else if( !/^[a-z0-9]([-](?![-])|[a-z0-9]){3,30}[a-z0-9]$/gm.test(this.state.info.title.trim())){
            return [false, 'Bileşeninizin ismi yalnızca latinize küçük harfler, rakamlar ve - işaretinden oluşabilir!'];
        }
        else{
            if( this.state.info.type === 'person') {
                if( this.state[this.state.info.type].name === null ){
                    return [false, 'Kişi bileşenine bir isim girmelisiniz!'];
                }else{
                    const name = this.state[this.state.info.type].name.trim();
                    if( (name.length > 30) || (name.length < 3) ){
                        return [false, 'Kişi bileşeninin ismi en az 3 en çok 30 karakter olabilir!'];
                    }
                }
                if( this.state[this.state.info.type].url === null ){
                    return [false, 'Kişi bileşenine tıklanıldığında yönlendirilecek bir URL girmelisiniz!'];
                }else{
                    if( !this.checkURL(this.state[this.state.info.type].url) ){
                        return [false, 'Kişi bileşenine tıklanıldığında yönlendirilecek olan URL\'niz geçersiz!'];
                    }
                }
            }
            if( this.state.info.type === 'fileList') {
                let err = null;
                for (const item of this.state.fileList.list) {
                    if( item.title === null ){
                        err = [false, 'Dosya adını boş bırakamazsınız!'];
                    }
                    else if( item.url === null ){
                        err = [false, 'Bir dosya yüklemelisiniz!'];
                    }
                    else{
                        const name = item.title.trim();
                        if( (name.length > 30) || (name.length < 3) ){
                            err = [false, 'Dosya adınız en az 3 en çok 30 karakter olabilir!'];
                        }
                    }
                }
                if(err !== null){
                    return err;
                }
            }
            return [true, {title: this.state.info.title, type: this.state.info.type , component: { ...this.state[this.state.info.type]}}];
        }
    }

    setComponentProperty = (component, key, value) => {
        this.setState({
            ...this.state,
            [component]: {
                ...this.state[component],
                [key]: value
            }
        });
    }

    resetComponentState = ()  => {
        this.setState({
            ...this.defaultState
        })
    }

    setComponentPropertyArray = (component, itemKey, key, value) => {
        const list = [...this.state[component].list];
        for (let i = 0; i < list.length; i++) {
            if(list[i].key === itemKey){
                list[i][key] = value;
                break;
            }
        }
        this.setComponentProperty(component, 'list', list);
    }

    setComponentPropertyArrayAppend = (component, obj) => {
        const list = [...this.state[component].list];
        list.push(obj);
        this.setComponentProperty(component, 'list', list);
    }

    setComponentPropertyArrayChangeOrder = (component, index1, index2) => {
        const list = [...this.state[component].list];
        const obj = { ...list[index1] };
        list[index1] = list[index2];
        list[index2] = obj;
        this.setComponentProperty(component, 'list', list);
    }

    setComponentPropertyArrayRemove = (component, itemKey) => {
        const list = [...this.state[component].list].filter(e => e.key !== itemKey);
        this.setComponentProperty(component, 'list', list);
    }

    colorPicker = (component, property, array=null, itemKey=null) => {
        const colorToRgb = (color) => {
            return (color.rgb.r.toString() + ',' + color.rgb.g.toString() + ',' + color.rgb.b.toString());
        }
        if(array === null){
            return(
                <TwitterPicker
                    triangle={'hide'}
                    onChangeComplete={(color) => this.setComponentProperty(component,[property], colorToRgb(color))}
                />
            );
        }else{
            return(
                <TwitterPicker
                    triangle={'hide'}
                    onChangeComplete={(color) => this.setComponentPropertyArray(component, itemKey, array, colorToRgb(color))}
                />
            );
        }

    }

    createComponent = (item) => {
        switch (item) {
            case null:
                return this.createNullItem();
            case 'title':
                return this.createTitle();
            case 'text':
                return this.createText();
            case 'slider':
                return this.createSlider();
            case 'bigCard':
                return this.createBigCard();
            case 'person':
                return this.createPerson();
            case 'embedYoutube':
                return this.createEmbedYoutube();
            case 'alertBox':
                return this.createAlertBox();
            case 'calendar':
                return this.createCalendar();
            case 'contentCorner':
                return this.createContentCorner();
            case 'cards':
                return this.createCards();
            case 'breadcrumb':
                return this.createBreadcrumb();
            case 'pageInfoBox':
                return this.createPageInfoBox();
            case 'image':
                return this.createImage();
            case 'comments':
                return this.createComments();
            case 'recommendedContents':
                return this.createRecommendedContents();
            case 'shareBar':
                return this.createShareBar();
            case 'lastAdded':
                return this.createLastAdded();
            case 'lastComments':
                return this.createLastComments();
            case 'personBox':
                return this.createPersonBox();
            case 'button':
                return this.createButton();
            case 'gallery':
                return this.createGallery();
            case 'fileList':
                return this.createFileList();
            case 'contentListing':
                return this.createContentListing();
            default:
                break;
        }
    }

    createComponentInfo = () => {
        return(
            <div className={'info'}>
                <Input
                    addonBefore={'Bileşen ismi'}
                    value={this.state.info.title}
                    onChange={e => this.setComponentProperty('info','title',e.target.value)}
                    spellCheck={false}
                />
                <small>* Bileşen ismi benzersiz olmalıdır.</small>
            </div>
        );
    }

    createNullItem = () => {
        return(
            <div className={'null component'}>
                henüz bir bileşen tipi seçmediniz
            </div>
        );
    }

    createTitle = () => {
        return(
            <div className={'title component'}>
                <div className={'component-header'}>
                    <div style={{flex: '1'}}/>
                    <Dropdown overlay={() => this.colorPicker('title','color')} placement={'bottomLeft'} trigger={['click']}>
                        <Button type={'dashed'}>
                            <span className={'color'} style={{backgroundColor: `rgb(${this.state.title.color})`}}/>
                            <span>Renk</span>
                        </Button>
                    </Dropdown>
                    <Select value={this.state.title.level} style={{ width: 150 }} onChange={level => this.setComponentProperty('title','level', level.toString())}>
                        <Option value={'1'}>1.Seviye Başlık</Option>
                        <Option value={'2'}>2.Seviye Başlık</Option>
                        <Option value={'3'}>3.Seviye Başlık</Option>
                        <Option value={'4'}>4.Seviye Başlık</Option>
                        <Option value={'5'}>5.Seviye Başlık</Option>
                    </Select>
                </div>
                <div className={'component-body'}>
                    <Input
                        placeholder={'Başlık'}
                        value={this.state.title.value}
                        onChange={e => this.setComponentProperty('title','value',e.target.value)}
                        spellCheck={false}
                    />
                    <small>Renk varsayılan değeri siyahtır. <b>[#000000]</b></small>
                </div>
            </div>
        );
    }

    fileCheck = file => {
        if(file.size > 15728640)
        {
            notification.error({
                message: 'Dosyanız yüklenemedi!',
                description: <span><i>{file.name}</i> adlı dosyanız fazla büyük! 15mb'den fazla olamaz!</span>
            });
            return false;
        }
        else if(!['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type))
        {
            notification.error({
                message: 'Dosyanız yüklenemedi!',
                description: <span><i>{file.name}</i> adlı dosyanızın uzantısı geçerisiz! Yalnızca .pdf, .doc ve .docx uzantıları kabul edilebilir!</span>
            });
            return false;
        }
        else{
            return true;
        }
    }

    fileUploadChange = (info, key) => {
        const { status } = info.file;
        if (status === 'uploading') {
            message.loading({content: <small><i>{info.file.name}</i> adlı dosyanız yükleniyor...</small>, key: info.file.uid}).then(r =>r);
        }
        if (status === 'done') {
            const res = JSON.parse(info.file.xhr.response);
            const file = this.props.config.cdnUrl + 'files/get?file=' + res.files[0];
            this.setComponentPropertyArray('fileList', key, 'name', info.file.name);
            this.setComponentPropertyArray('fileList', key, 'url', file);
            message.success({content: <small><i>{info.file.name}</i> adlı dosyanız başarıyla yüklendi.</small>, key: info.file.uid, duration: 4}).then(r =>r);
        } else if (status === 'error') {
            message.error({content: <small><i>{info.file.name}</i> adlı dosyanız yüklenemedi!</small>, key: info.file.uid, duration: 4}).then(r =>r);
        }
    }

    createFileList = () => {
        return (
            <div className={'file-list component'}>
                <div className={'component-body'}>
                    {this.state.fileList.list.map((e, i) => {
                        return (
                            <div className={'list-header-div'} key={e.key}>
                                <div className={'in'}>
                                    <Input
                                        placeholder={'Dosya başlığı'}
                                        value={e.title}
                                        onChange={elem => this.setComponentPropertyArray('fileList', e.key, 'title', elem.target.value)}
                                        spellCheck={false}
                                    />
                                    <Upload
                                        onChange={info => this.fileUploadChange(info, e.key)}
                                        beforeUpload={this.fileCheck}
                                        action={this.props.config.cdnUrl + 'files/set'}
                                        method={'POST'}
                                        data={{token: this.props.config.token}}
                                        showUploadList={false}
                                    >
                                        <Button icon={<UploadOutlined/>}>Dosya yükle</Button>
                                    </Upload>,
                                    {i !== 0 && <Button
                                        icon={<CaretUpOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('fileList', i, i - 1)}
                                    />}
                                    {i !== (this.state.fileList.list.length - 1) && <Button
                                        icon={<CaretDownOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('fileList', i, i + 1)}
                                    />}
                                    {this.state.fileList.list.length !== 1 && <Button
                                        icon={<DeleteOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayRemove('fileList', e.key)}
                                    />}
                                </div>
                                <small><i>{e.name}</i></small>
                            </div>
                        );
                    })}
                    <Button
                        type={'dashed'}
                        onClick={() => {
                            const obj = {
                                key: Math.random(),
                                title: null,
                                name: null,
                                url: null
                            };
                            this.setComponentPropertyArrayAppend('fileList', obj);
                        }}
                        style={{marginBottom: '.5rem'}}
                    >
                        Yeni Dosya Ekle
                    </Button>
                </div>
            </div>
        );
    }

    createText = () => {
        return(
            <div className={'text-box component'}>
                <div className={'component-body'}>
                    <CKEditor
                        editor={ Editor }
                        config={ {
                            toolbar: {
                                items: [
                                    'heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    'link',
                                    'blockQuote',
                                    'bulletedList',
                                    'numberedList',
                                    '|',
                                    'alignment',
                                    'fontColor',
                                    'fontBackgroundColor',
                                    'underline',
                                    'highlight',
                                    '|',
                                    'imageInsert',
                                    'horizontalLine',
                                    'undo',
                                    'redo'
                                ],
                                shouldNotGroupWhenFull: true
                            },
                            image: {
                                toolbar: [
                                    'toggleImageCaption',
                                    'imageTextAlternative',
                                    'linkImage'
                                ]
                            },
                            simpleUpload: {
                                uploadUrl: this.props.config.cdnUrl + 'images/set?token=' + this.props.config.token,
                            },
                            language: 'tr',
                        } }
                        onReady={ editor => {
                            this.setState({ editor })
                            editor.setData(this.state.text.data);
                        }}
                        onChange={() => {
                            this.setComponentProperty('text','data', this.state.editor.getData());
                        }}
                    />
                </div>
            </div>
        );
    }

    createSlider = () => {
        return(
            <div className={'slider component'}>
                <div className={'component-header'}>
                    <Checkbox
                        checked={this.state.slider.auto}
                        onChange={e => this.setComponentProperty('slider', 'auto', e.target.checked)}
                    >
                        Slaytlar arası otomatik geçiş olsun
                    </Checkbox>
                    <div style={{flex: '1'}}/>
                </div>
                <div className={'component-body'}>
                    {this.state.slider.list.map((e, i) => {
                        return(
                            <div className={'list-header-div'} key={e.key}>
                                <div className={'in'}>
                                    <Select value={e.position} style={{ width: 150 }} onChange={level => this.setComponentPropertyArray('slider','position', level)}>
                                        <Option value={'rightUp'}>Sağ Üst Köşe</Option>
                                        <Option value={'rightDown'}>Sağ Alt Köşe</Option>
                                        <Option value={'leftUp'}>Sol Üst Köşe</Option>
                                        <Option value={'leftDown'}>Sol Alt Köşe</Option>
                                        <Option value={'topCenter'}>Merkez Üst</Option>
                                        <Option value={'bottomCenter'}>Merkez Alt</Option>
                                    </Select>
                                    <Dropdown overlay={() => this.colorPicker('slider','list', 'backgroundColor', e.key)} placement={'bottomLeft'} trigger={['click']}>
                                        <Button type={'dashed'}>
                                            <span className={'color'} style={{backgroundColor: `rgb(${e.backgroundColor})`}}/>
                                            <span>Açıklama Yazılarının Arka Plan Rengi</span>
                                        </Button>
                                    </Dropdown>
                                    <Dropdown overlay={() => this.colorPicker('slider','list', 'fontColor', e.key)} placement={'bottomLeft'} trigger={['click']}>
                                        <Button type={'dashed'}>
                                            <span className={'color'} style={{backgroundColor: `rgb(${e.fontColor})`}}/>
                                            <span>Yazı Rengi</span>
                                        </Button>
                                    </Dropdown>
                                    <div style={{flex: '1'}}/>
                                    {i !== 0 && <Button
                                        icon={<CaretUpOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('slider', i, i-1)}
                                    />}
                                    {i !== (this.state.slider.list.length-1) && <Button
                                        icon={<CaretDownOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('slider', i, i+1)}
                                    />}
                                    {this.state.slider.list.length !== 1 && <Button
                                        icon={<DeleteOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayRemove('slider', e.key)}
                                    />}
                                </div>
                                <Input
                                    placeholder={'Slaytta gösterilecek görsel URL\'si'}
                                    value={e.image}
                                    onChange={elem => this.setComponentPropertyArray('slider', e.key,'image', elem.target.value)}
                                    spellCheck={false}
                                />
                                <TextArea
                                    placeholder={'Slayt başlığı (isteğe bağlı)'}
                                    value={e.title}
                                    onChange={elem => this.setComponentPropertyArray('slider', e.key,'title', elem.target.value)}
                                    spellCheck={false}
                                    style={{ resize: 'none' }}
                                    rows={3}
                                />
                                <Input
                                    placeholder={'Tıklanıldığında yönlendirilecek URL (isteğe bağlı)'}
                                    value={e.URL}
                                    onChange={elem => this.setComponentPropertyArray('slider', e.key,'URL', elem.target.value)}
                                    spellCheck={false}
                                />
                            </div>
                        );
                    })}
                    <Button
                        type={'dashed'}
                        onClick={() => {
                            const obj = {
                                key: Math.random(),
                                backgroundColor: '255,255,255',
                                fontColor: '0,0,0',
                                image: null,
                                title: null,
                                URL: null,
                                position: 'bottomCenter'
                            };
                            this.setComponentPropertyArrayAppend('slider', obj);
                        }}
                    >
                        Yeni Slayt Ekle
                    </Button>
                </div>
            </div>
        );
    }

    createBigCard = () => {
        return(
            <div className={'big-card component'}>
                <div className={'component-header'}>
                    <div style={{flex: '1'}}/>
                    <Dropdown overlay={() => this.colorPicker('bigCard','backgroundColor1')} placement={'bottomLeft'} trigger={['click']}>
                        <Button type={'dashed'}>
                            <span className={'color'} style={{backgroundColor: `rgb(${this.state.bigCard.backgroundColor1})`}}/>
                            <span>Arka Plan Renk 1</span>
                        </Button>
                    </Dropdown>
                    <Dropdown overlay={() => this.colorPicker('bigCard','backgroundColor2')} placement={'bottomLeft'} trigger={['click']}>
                        <Button type={'dashed'}>
                            <span className={'color'} style={{backgroundColor: `rgb(${this.state.bigCard.backgroundColor2})`}}/>
                            <span>Arka Plan Renk 2</span>
                        </Button>
                    </Dropdown>
                    <Dropdown overlay={() => this.colorPicker('bigCard','fontColor')} placement={'bottomLeft'} trigger={['click']}>
                        <Button type={'dashed'}>
                            <span className={'color'} style={{backgroundColor: `rgb(${this.state.bigCard.fontColor})`}}/>
                            <span>Yazı Rengi</span>
                        </Button>
                    </Dropdown>
                </div>
                <div className={'component-body'}>
                    <Input
                        placeholder={'Kartta görünecek metin'}
                        value={this.state.bigCard.value}
                        onChange={e => this.setComponentProperty('bigCard','value', e.target.value)}
                        spellCheck={false}
                    />
                    <Input
                        placeholder={'Karta tıklanıldığına yönlendirilecek URL (isteğe bağlı)'}
                        value={this.state.bigCard.url}
                        onChange={e => this.setComponentProperty('bigCard','url', e.target.value)}
                        spellCheck={false}
                    />
                    <div className={'checkbox-div'}>
                        <Checkbox
                            checked={this.state.bigCard.blank}
                            onChange={e => this.setComponentProperty('bigCard','blank', e.target.checked)}
                        >
                            Yönlendirilen adres yeni sekmede açılsın
                        </Checkbox>
                        <Checkbox
                            checked={this.state.bigCard.radius}
                            onChange={e => this.setComponentProperty('bigCard','radius', e.target.checked)}
                        >
                            Kart kenarlarını yuvarlaklaştır
                        </Checkbox>
                    </div>
                </div>
            </div>
        );
    }

    createPerson = () => {
        return(
            <div className={'person component'}>
                <div className={'component-body'}>
                    <Input
                        placeholder={'Kişi ismi'}
                        value={this.state.person.name}
                        onChange={e => this.setComponentProperty('person','name', e.target.value)}
                        spellCheck={false}
                    />
                    <TextArea
                        rows={4}
                        placeholder={'Kişi biyografisi (isteğe bağlı)'}
                        value={this.state.person.bio}
                        onChange={e => this.setComponentProperty('person','bio', e.target.value)}
                        spellCheck={false}
                    />
                    <Input
                        placeholder={'Kişi profil görseli URL\'si (isteğe bağlı)'}
                        value={this.state.person.ppUrl}
                        onChange={e => this.setComponentProperty('person','ppUrl', e.target.value)}
                        spellCheck={false}
                    />
                    <Input
                        placeholder={'Kişiye tıklanıldığında yönlendirilecek URL (isteğe bağlı)'}
                        value={this.state.person.url}
                        onChange={e => this.setComponentProperty('person','url', e.target.value)}
                        spellCheck={false}
                    />
                    <div className={'checkbox-div'}>
                        <Checkbox
                            checked={this.state.person.last3content}
                            onChange={e => this.setComponentProperty('person','last3content', e.target.checked)}
                        >
                            Kişiye ait içeriklerden son 3'ü kişi kutusunda listelensin
                        </Checkbox>
                        <Checkbox
                            checked={this.state.person.showBio}
                            onChange={e => this.setComponentProperty('person','showBio', e.target.checked)}
                        >
                            Kişi biyografisinin bir kısmı kişi kutusunda gösterilsin
                        </Checkbox>
                    </div>
                </div>
            </div>
        );
    }

    createEmbedYoutube = () => {
        return(
            <div className={'embed-youtube component'}>
                <div className={'component-body'}>
                    <Input
                        placeholder={'Youtube video URL\'si'}
                        value={this.state.embedYoutube.url}
                        onChange={e => this.setComponentProperty('embedYoutube','url',e.target.value)}
                        spellCheck={false}
                    />
                </div>
            </div>
        );
    }

    createAlertBox = () => {
        return(
            <div className={'alert-box component'}>
                <div className={'component-header'}>
                    <div style={{flex: '1'}}/>
                    <Select value={this.state.alertBox.type} style={{ width: 120 }} onChange={type => this.setComponentProperty('alertBox','type', type)}>
                        <Option value={'success'}>Başarı</Option>
                        <Option value={'info'}>Bilgi</Option>
                        <Option value={'warning'}>Uyarı</Option>
                        <Option value={'error'}>Hata</Option>
                    </Select>
                </div>
                <div className={'component-body'}>
                    <TextArea
                        rows={4}
                        value={this.state.alertBox.content}
                        placeholder={'Uyarı kutusu içeriği'}
                        onChange={e => this.setComponentProperty('alertBox','content', e.target.value)}
                    />
                    <Input
                        placeholder={'Uyarı kutusu başlığı (isteğe bağlı)'}
                        value={this.state.alertBox.title}
                        onChange={e => this.setComponentProperty('alertBox','title', e.target.value)}
                        spellCheck={false}
                    />
                    <div className={'checkbox-div'}>
                        <Checkbox
                            checked={this.state.alertBox.icon}
                            onChange={e => this.setComponentProperty('alertBox','icon', e.target.checked)}
                        >
                            Uyarı kutusunun tipine göre ikon gösterilsin
                        </Checkbox>
                    </div>
                </div>
            </div>
        );
    }

    createCalendar = () => {
        return(
            <div className={'calendar component'}>
                <div className={'component-body'}>
                    {this.state.calendar.list.map((e, i) => {
                        return(
                            <div className={'list-header-div'} key={e.key}>
                                <div className={'in'}>
                                    <DatePicker
                                        locale={locale}
                                        value={moment(e.date)}
                                        onChange={m => {
                                            if(m === null)
                                                this.setComponentPropertyArray('calendar', e.key,'date', moment(Date.now()));
                                            else
                                                this.setComponentPropertyArray('calendar', e.key,'date', m)
                                        }}
                                    />
                                    <Input
                                        placeholder={'Etkinlik Başlığı'}
                                        value={e.title}
                                        onChange={elem => this.setComponentPropertyArray('calendar', e.key,'title', elem.target.value)}
                                        spellCheck={false}
                                    />
                                    {i !== 0 && <Button
                                        icon={<CaretUpOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('calendar', i, i-1)}
                                    />}
                                    {i !== (this.state.calendar.list.length-1) && <Button
                                        icon={<CaretDownOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('calendar', i, i+1)}
                                    />}
                                    {this.state.calendar.list.length !== 1 && <Button
                                        icon={<DeleteOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayRemove('calendar', e.key)}
                                    />}
                                </div>
                                <TextArea
                                    placeholder={'İçerik açıklaması'}
                                    value={e.description}
                                    onChange={elem => this.setComponentPropertyArray('calendar', e.key,'description', elem.target.value)}
                                    spellCheck={false}
                                    rows={3}
                                    style={{ resize: 'none' }}
                                />
                                <Input
                                    placeholder={'Tıklanıldığında yönlendirilecek URL (isteğe bağlı)'}
                                    value={e.URL}
                                    onChange={elem => this.setComponentPropertyArray('calendar', e.key,'URL', elem.target.value)}
                                    spellCheck={false}
                                />
                            </div>
                        );
                    })}
                    <Button
                        type={'dashed'}
                        onClick={() => {
                            const obj = {
                                key: Math.random(),
                                date: moment(Date.now()),
                                title: null,
                                description: null,
                                URL: null
                            };
                            this.setComponentPropertyArrayAppend('calendar', obj);
                        }}
                    >
                        Yeni Etkinlik Ekle
                    </Button>
                </div>
            </div>
        );
    }

    createContentCorner = () => {
        return(
            <div className={'content-corner component'}>
                <div className={'component-body'}>
                    <div style={{ width: '100%', borderBottom: '1px solid #dddddd', marginBottom: '1rem', paddingBottom: '.5rem'}}>
                        <Input
                            placeholder={'İçerik köşesi başlığı'}
                            value={this.state.contentCorner.title}
                            onChange={elem => this.setComponentProperty('contentCorner', 'title', elem.target.value)}
                            spellCheck={false}
                        />
                    </div>
                    {this.state.contentCorner.list.map((e, i) => {
                        return(
                            <div className={'list-header-div'} key={e.key}>
                                <div className={'in'}>
                                    <Input
                                        placeholder={'İçerik URL\'si'}
                                        value={e.url}
                                        onChange={elem => this.setComponentPropertyArray('contentCorner', e.key,'url', elem.target.value)}
                                        spellCheck={false}
                                    />
                                    {i !== 0 && <Button
                                        icon={<CaretUpOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('contentCorner', i, i-1)}
                                    />}
                                    {i !== (this.state.contentCorner.list.length-1) && <Button
                                        icon={<CaretDownOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('contentCorner', i, i+1)}
                                    />}
                                    {this.state.contentCorner.list.length !== 1 && <Button
                                        icon={<DeleteOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayRemove('contentCorner', e.key)}
                                    />}
                                </div>
                                <Input
                                    placeholder={'İçerik başlığı'}
                                    value={e.text}
                                    onChange={elem => this.setComponentPropertyArray('contentCorner', e.key,'text', elem.target.value)}
                                    spellCheck={false}
                                />
                            </div>
                        );
                    })}
                    <Button
                        type={'dashed'}
                        onClick={() => {
                            const obj = {
                                key: Math.random(),
                                url: null,
                                text: null
                            };
                            this.setComponentPropertyArrayAppend('contentCorner', obj);
                        }}
                        style={{marginBottom: '.5rem'}}
                    >
                        Yeni İçerik Ekle
                    </Button>
                </div>
            </div>
        );
    }

    createGallery = () => {
        return(
            <div className={'gallery component'}>
                <div className={'component-body'}>
                    {this.state.gallery.list.map((e, i) => {
                        return(
                            <div className={'list-header-div'} key={e.key}>
                                <div className={'in'}>
                                    <Input
                                        placeholder={'Görsel URL\'si'}
                                        value={e.url}
                                        onChange={elem => this.setComponentPropertyArray('gallery', e.key,'url', elem.target.value)}
                                        spellCheck={false}
                                    />
                                    {i !== 0 && <Button
                                        icon={<CaretUpOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('gallery', i, i-1)}
                                    />}
                                    {i !== (this.state.gallery.list.length-1) && <Button
                                        icon={<CaretDownOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('gallery', i, i+1)}
                                    />}
                                    {this.state.gallery.list.length !== 1 && <Button
                                        icon={<DeleteOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayRemove('gallery', e.key)}
                                    />}
                                </div>
                            </div>
                        );
                    })}
                    <Button
                        type={'dashed'}
                        onClick={() => {
                            const obj = {
                                key: Math.random(),
                                url: null
                            };
                            this.setComponentPropertyArrayAppend('gallery', obj);
                        }}
                        style={{marginBottom: '.5rem'}}
                    >
                        Yeni Görsel Ekle
                    </Button>
                </div>
            </div>
        );
    }

    createCards = () => {
        return(
            <div className={'cards component'}>
                <div className={'component-body'}>
                    {this.state.cards.list.map((e, i) => {
                        return(
                            <div className={'list-header-div'} key={e.key}>
                                <div className={'in'}>
                                    <DatePicker
                                        showTime={{ format: 'HH:mm' }}
                                        locale={locale}
                                        value={moment(e.date)}
                                        onChange={m => {
                                            if(m === null)
                                                this.setComponentPropertyArray('cards', e.key,'date', moment(Date.now()));
                                            else
                                                this.setComponentPropertyArray('cards', e.key,'date', m);
                                        }}
                                    />
                                    <Dropdown overlay={() => this.colorPicker('cards','list', 'color1', e.key)} placement={'bottomLeft'} trigger={['click']}>
                                        <Button type={'dashed'}>
                                            <span className={'color'} style={{backgroundColor: `rgb(${e.color1})`}}/>
                                            <span>Kart Çizgisi Rengi 1</span>
                                        </Button>
                                    </Dropdown>
                                    <Dropdown overlay={() => this.colorPicker('cards','list', 'color2', e.key)} placement={'bottomLeft'} trigger={['click']}>
                                        <Button type={'dashed'}>
                                            <span className={'color'} style={{backgroundColor: `rgb(${e.color2})`}}/>
                                            <span>Kart Çizgisi Rengi 2</span>
                                        </Button>
                                    </Dropdown>
                                    <div style={{flex: '1'}}/>
                                    {i !== 0 && <Button
                                        icon={<CaretUpOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('cards', i, i-1)}
                                    />}
                                    {i !== (this.state.cards.list.length-1) && <Button
                                        icon={<CaretDownOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('cards', i, i+1)}
                                    />}
                                    {this.state.cards.list.length !== 1 && <Button
                                        icon={<DeleteOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayRemove('cards', e.key)}
                                    />}
                                </div>
                                <Input
                                    placeholder={'Kart başlığı'}
                                    value={e.title}
                                    onChange={elem => this.setComponentPropertyArray('cards', e.key,'title', elem.target.value)}
                                    spellCheck={false}
                                />
                                <Input
                                    placeholder={'Kart üzerinde görünecek görselin URL\'si'}
                                    value={e.image}
                                    onChange={elem => this.setComponentPropertyArray('cards', e.key,'image', elem.target.value)}
                                    spellCheck={false}
                                />
                                <TextArea
                                    rows={2}
                                    placeholder={'Kart içeriği (isteğe bağlı)'}
                                    value={e.content}
                                    onChange={elem => this.setComponentPropertyArray('cards', e.key,'content', elem.target.value)}
                                    spellCheck={false}
                                />
                                <Input
                                    placeholder={'Tıklanıldığında yönlendirilecek URL (isteğe bağlı)'}
                                    value={e.URL}
                                    onChange={elem => this.setComponentPropertyArray('cards', e.key,'URL', elem.target.value)}
                                    spellCheck={false}
                                />
                            </div>
                        );
                    })}
                    <Button
                        type={'dashed'}
                        onClick={() => {
                            const obj = {
                                key: Math.random(),
                                color1: '0,159,214',
                                color2: '72,181,140',
                                date: moment(Date.now()),
                                title: null,
                                image: null,
                                content: null,
                                URL: null
                            };
                            this.setComponentPropertyArrayAppend('cards', obj);
                        }}
                    >
                        Yeni Kart Ekle
                    </Button>
                </div>
            </div>
        );
    }

    createBreadcrumb = () => {
        return(
            <div className={'breadcrumb component'}>
                <div className={'component-body'}>
                    {this.state.breadcrumb.list.map((e, i) => {
                        return(
                            <div className={'list-header-div'} key={e.key}>
                                <div className={'in'}>
                                    <Input
                                        placeholder={'Menü başlığı'}
                                        value={e.title}
                                        onChange={elem => this.setComponentPropertyArray('breadcrumb', e.key,'title', elem.target.value)}
                                        spellCheck={false}
                                    />
                                    {i !== 0 && <Button
                                        icon={<CaretUpOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('breadcrumb', i, i-1)}
                                    />}
                                    {i !== (this.state.breadcrumb.list.length-1) && <Button
                                        icon={<CaretDownOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayChangeOrder('breadcrumb', i, i+1)}
                                    />}
                                    {this.state.breadcrumb.list.length !== 1 && <Button
                                        icon={<DeleteOutlined/>}
                                        type={'dashed'}
                                        onClick={() => this.setComponentPropertyArrayRemove('breadcrumb', e.key)}
                                    />}
                                </div>
                                <Input
                                    placeholder={'Menüye tıklanıldığında yönlendirilecek URL (isteğe bağlı)'}
                                    value={e.URL}
                                    onChange={elem => this.setComponentPropertyArray('breadcrumb', e.key,'URL', elem.target.value)}
                                    spellCheck={false}
                                />
                            </div>
                        );
                    })}
                    <Button
                        type={'dashed'}
                        onClick={() => {
                            const obj = {
                                key: Math.random(),
                                title: null,
                                URL: null
                            };
                            this.setComponentPropertyArrayAppend('breadcrumb', obj);
                        }}
                        style={{marginBottom: '.5rem'}}
                    >
                        Yeni Menü Ekle
                    </Button>
                </div>
            </div>
        );
    }

    createPageInfoBox = () => {
        return(
            <div className={'page-info-box component'}>
                <div className={'component-header'}>
                    <div style={{flex: '1'}}/>
                    <Select
                        value={this.state.pageInfoBox.person}
                        style={{ width: 220 }}
                        onChange={person => this.setComponentProperty('pageInfoBox','person', person)}
                    >
                        <Option value={'kisi-sec'} key={'kisi-sec'}>Kişi seç</Option>
                        {this.state.persons.map(person => (
                            <Option value={person[0]} key={person[0]}>{person[1]}</Option>
                        ))}
                    </Select>
                </div>
                <div className={'component-body'}>
                    <div className={'checkbox-div'}>
                        <Checkbox
                            checked={this.state.pageInfoBox.showDate}
                            onChange={e => this.setComponentProperty('pageInfoBox','showDate', e.target.checked)}
                        >
                            Sayfanın yayınlanma tarihi gösterilsin
                        </Checkbox>
                        <Checkbox
                            checked={this.state.pageInfoBox.showCommentCount}
                            onChange={e => this.setComponentProperty('pageInfoBox','showCommentCount', e.target.checked)}
                        >
                            Yorum sayısı gösterilsin
                        </Checkbox>
                    </div>
                </div>
            </div>
        );
    }

    createImage = () => {
        return(
            <div className={'image component'}>
                <div className={'component-body'}>
                    <Input
                        placeholder={'Görsel URL\'si'}
                        value={this.state.image.url}
                        onChange={e => this.setComponentProperty('image','url',e.target.value)}
                        spellCheck={false}
                    />
                    <Input
                        placeholder={'Görsel açıklaması (isteğe bağlı)'}
                        value={this.state.image.caption}
                        onChange={e => this.setComponentProperty('image','caption',e.target.value)}
                        spellCheck={false}
                    />
                </div>
            </div>
        );
    }

    createComments = () => {
        return(
            <div className={'comments component'}>
                <div className={'component-body'}>
                    Yorum kutusu
                </div>
            </div>
        );
    }

    createRecommendedContents = () => {
        return(
            <div className={'recommended-contents component'}>
                <div className={'component-body'}>
                    Önerilen içerikler
                </div>
            </div>
        );
    }

    createLastComments = () => {
        return(
            <div className={'last-comments component'}>
                <div className={'component-body'}>
                    Son yorumlar
                </div>
            </div>
        );
    }

    createLastAdded = () => {
        return(
            <div className={'last-added component'}>
                <div className={'component-body'}>
                    Son eklenen içerikler
                </div>
            </div>
        );
    }

    createShareBar = () => {
        return(
            <div className={'share-bar component'}>
                <div className={'component-body'}>
                    İçeriği paylaş kutusu
                </div>
            </div>
        );
    }

    createPersonBox = () => {
        return(
            <div className={'person-box component'}>
                <div className={'component-body'}>
                    <Select
                        value={this.state.personBox.person}
                        style={{ width: 220 }}
                        onChange={person => this.setComponentProperty('personBox','person', person)}
                    >
                        <Option value={'kisi-sec'} key={'kisi-sec'}>Kişi seç</Option>
                        {this.state.persons.map(person => (
                            <Option value={person[0]} key={person[0]}>{person[1]}</Option>
                        ))}
                    </Select>
                </div>
            </div>
        );
    }

    createButton = () => {
        return(
            <div className={'button component'}>
                <div className={'component-header'}>
                    <div style={{flex: '1'}}/>
                    <Select value={this.state.button.type} style={{ width: 150 }} onChange={type => this.setComponentProperty('button','type', type.toString())}>
                        <Option value={'normal'}>Normal</Option>
                        <Option value={'primary'}>Birincil</Option>
                        <Option value={'dashed'}>Kesik çizgili</Option>
                        <Option value={'text'}>Sadece yazı</Option>
                    </Select>
                </div>
                <div className={'component-body'}>
                    <Input
                        placeholder={'Butonda görünecek metin'}
                        value={this.state.button.text}
                        onChange={e => this.setComponentProperty('button','text', e.target.value)}
                        spellCheck={false}
                    />
                    <Input
                        placeholder={'Tıklanıldığında yönlendirilecek URL'}
                        value={this.state.button.url}
                        onChange={e => this.setComponentProperty('button','url', e.target.value)}
                        spellCheck={false}
                    />
                </div>
            </div>
        );
    }

    createContentListing = () => {
        return(
            <div className={'content-listing component'}>
                <div className={'component-body'}>
                    <Transfer
                        listStyle={{
                            width: 250,
                            height: 300,
                        }}
                        dataSource={this.state.contentListingList.map(item => {
                            let type;
                            if(item.type === 'tag'){
                                type = ' [Etiket]';
                            }
                            else if(item.type === 'person'){
                                type = ' [Kişi]';
                            }
                            else if(item.type === 'scheme'){
                                type = ' [Şablon]';
                            }
                            return {
                                key: item.id,
                                text: item.name + type
                            };
                        })}
                        titles={['Seçenekler', 'Listelenecekler']}
                        render={item => item.text}
                        targetKeys={this.state.contentListing.list}
                        onChange={targetKeys => {
                            this.setComponentProperty('contentListing','list', targetKeys);
                        }}
                        showSelectAll={false}
                        locale={{
                            itemUnit: 'madde',
                            itemsUnit: 'maddeler',
                            notFoundContent: 'içerik yok',
                            searchPlaceholder: 'ara'
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default PageComponents;
