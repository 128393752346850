import React, {Component} from 'react';
import {Menu, Tag} from 'antd';
import logo from '../../images/logo.png';
import {
    AppstoreAddOutlined, AreaChartOutlined, BookOutlined,
    BuildOutlined, CheckSquareOutlined, CloseSquareOutlined,
    CommentOutlined, FileAddOutlined, FolderOutlined,
    GroupOutlined, HomeOutlined,
    PictureOutlined, PullRequestOutlined, SettingOutlined, UnorderedListOutlined
} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const {SubMenu} = Menu;

class NavigationMenu extends Component {
    state = {
        menuKey: {
            '/': 'nm-1',
            '/sayfalar/sayfa-listesi': 'nm-3',
            '/sayfalar/yeni-sayfa-ekle': 'nm-4',
            '/sayfalar/sablon-listesi': 'nm-5',
            '/sayfalar/yeni-sablon-ekle': 'nm-6',
            '/sayfa-bilesenleri/sayfa-bilesenleri-listesi': 'nm-8',
            '/sayfa-bilesenleri/yeni-bilesen-ekle': 'nm-9',
            '/galeri': 'nm-10',
            '/yorumlar/onaylanmamislar': 'nm-12',
            '/yorumlar/onaylanmislar': 'nm-13',
            '/istatistikler': 'nm-14',
            '/yonlendirmeler': 'nm-15',
            '/ayarlar': 'nm-16',
        }
    };
    getMenuKeyFromPath = (path) => {
        if(this.state.menuKey.hasOwnProperty(path)){
            return this.state.menuKey[path];
        }else{
            return 'nm-1';
        }
    }
    render() {
        const defaultKey = this.getMenuKeyFromPath(window.location.pathname);
        return (
            <div className={'navigation-menu'}>
                <div className={'navigation-header'}>
                    <img src={logo} alt={'logo'}/>
                </div>
                <Menu
                    className={'menu'}
                    defaultSelectedKeys={[defaultKey]}
                    mode={'inline'}
                    theme={'light'}
                    forceSubMenuRender={false}
                >
                    <Menu.Item key={'nm-1'} icon={<HomeOutlined/>}>
                        <Link to={'/'}>Ana sayfa</Link>
                    </Menu.Item>
                    <SubMenu key={'nm-2'} title={'Sayfalar'} icon={<FolderOutlined/>}>
                        <Menu.Item key={'nm-3'} icon={<BookOutlined/>}>
                            <Link to={'/sayfalar/sayfa-listesi'}>Sayfa listesi</Link>
                        </Menu.Item>
                        <Menu.Item key={'nm-4'} icon={<FileAddOutlined/>}>
                            <Link to={'/sayfalar/yeni-sayfa-ekle'}>Yeni sayfa ekle</Link>
                        </Menu.Item>
                        <Menu.Item key={'nm-5'} icon={<GroupOutlined/>}>
                            <Link to={'/sayfalar/sablon-listesi'}>Şablon listesi</Link>
                        </Menu.Item>
                        <Menu.Item key={'nm-6'} icon={<FileAddOutlined/>}>
                            <Link to={'/sayfalar/yeni-sablon-ekle'}>Yeni şablon ekle</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key={'nm-7'} title={'Sayfa Bileşenleri'} icon={<BuildOutlined/>}>
                        <Menu.Item key={'nm-8'} icon={<UnorderedListOutlined/>}>
                            <Link to={'/sayfa-bilesenleri/sayfa-bilesenleri-listesi'}>Sayfa bileşenleri listesi</Link>
                        </Menu.Item>
                        <Menu.Item key={'nm-9'} icon={<AppstoreAddOutlined/>}>
                            <Link to={'/sayfa-bilesenleri/yeni-bilesen-ekle'}>Yeni bileşen ekle</Link>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key={'nm-10'} icon={<PictureOutlined/>}>
                        <Link to={'/galeri'}>Galeri</Link>
                    </Menu.Item>
                    <SubMenu key={'nm-11'} title={'Yorumlar'} icon={<CommentOutlined/>}>
                        <Menu.Item key={'nm-12'} icon={<CloseSquareOutlined/>}>
                            <Link to={'/yorumlar/onaylanmamislar'}>Onaylanmamış yorumlar</Link>
                        </Menu.Item>
                        <Menu.Item key={'nm-13'} icon={<CheckSquareOutlined/>}>
                            <Link to={'/yorumlar/onaylanmislar'}>Onaylanmış yorumlar</Link>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key={'nm-14'} icon={<AreaChartOutlined/>}>
                        <Link to={'/istatistikler'}>İstatistikler</Link>
                    </Menu.Item>
                    <Menu.Item key={'nm-15'} icon={<PullRequestOutlined/>}>
                        <Link to={'/yonlendirmeler'}>Yönlendirmeler</Link>
                    </Menu.Item>
                    <Menu.Item key={'nm-16'} icon={<SettingOutlined/>}>
                        <Link to={'/ayarlar'}>Ayarlar</Link>
                    </Menu.Item>
                </Menu>
                <div className={'navigation-footer'}>
                    <div>
                        <a
                            href={'https://halisyucel.me'}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            <Tag color={'blue'}>Halis Yücel</Tag>
                        </a>
                        tarafından tasarlandı
                    </div>
                </div>
            </div>
        );
    }
}

export default NavigationMenu;
