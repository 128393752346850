import PageComponents from '../common-use/pageComponents';
import '../../stylesheets/page-components/page-components.scss';
import {connect} from 'react-redux';

class EditableComponent extends PageComponents {
    setComponentProperty = (component, key, value) => {
        const newState = {
            info: {
                ...this.state.info,
                type: this.props.componentType
            },
            [component]: {
                ...this.state[component],
                [key]: value
            }
        };
        this.setState(newState);
        this.props.handleChange(newState);
    }
    componentDidMount() {
        this.resetComponentState();
        this.getPersons();
        this.getContentListingTitles();
        this.setState({ ...this.props.content });
        this.props.handleChange({
            info: { ...this.state.info, ...this.props.content.info },
            [this.props.componentType] : {
                ...this.state[this.props.componentType],
                ...this.props.content[this.props.componentType]
            }});
    }
    render() {
        return(
            <div className={'component-edit-area scheme-area'}>
                {this.createComponent(this.props.componentType)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {config: state.config}
}
export default connect(mapStateToProps, null)(EditableComponent);
