import {Component} from 'react';
import {Button, Divider, Modal, notification, PageHeader, Popconfirm, Space, Table, Tag} from 'antd';
import axios from 'axios';
import {ClockCircleOutlined, MailOutlined, UserOutlined} from '@ant-design/icons';

class CommentList extends Component {
    state = {
        approved: 1,
        loading: true,
        modal: {
            visible: false,
            data: {}
        },
        pagination: {
            current: 1,
            total: 10,
            pageSize: 10,
        },
        columns: [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px'
            },
            {
                title: 'Yorum Yazarı',
                dataIndex: 'commentAuthor',
                key: 'commentAuthor',
            },
            {
                title: 'E-Posta Adresi',
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: 'Sayfa Başlığı',
                dataIndex: 'pageTitle',
                key: 'pageTitle',
            },
            {
                title: 'Cevap Id\'si',
                dataIndex: 'reply',
                key: 'reply',
                width: '100px'
            },
            {
                title: 'Oluşturulma Tarihi',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: 'Olaylar',
                dataIndex: 'events',
                key: 'events',
                width: '280px',
                render: comment => (
                    <Space size={'middle'}>
                        <Button
                            onClick={() => this.showComment(comment)}
                            key={'1'}
                            size={'small'}
                        >
                            Yorumu Görüntüle
                        </Button>
                        <Popconfirm
                            title={(this.state.approved === 1 ? 'Bu yorumdan onayı geri almak' : 'Bu yorumu onaylamak') + ' istediğinize emin misiniz?'}
                            okText={'Evet'}
                            cancelText={'Hayır'}
                            placement={'topRight'}
                            onConfirm={() => this.editComment(comment.id)}
                            key={'2'}
                        >
                            <Button
                                type={'dashed'}
                                danger={true}
                                size={'small'}
                            >
                                {this.state.approved === 1 ? 'Onayı Geri Al' : 'Onayla'}
                            </Button>
                        </Popconfirm>
                        <Popconfirm
                            title={'Bu yorumu ve gelen cevapları silmek istediğinize emin misiniz?'}
                            okText={'Evet'}
                            cancelText={'Hayır'}
                            placement={'topRight'}
                            onConfirm={() => this.deleteComment(comment.id)}
                            key={'3'}
                        >
                            <Button
                                type={'danger'}
                                size={'small'}
                            >
                                Sil
                            </Button>
                        </Popconfirm>
                    </Space>
                ),
            }
        ],
        data: []
    };
    error = () => {
        notification.error({
            message: 'Bir Hata Oluştu!',
            description: 'database\'ye bağlanılamadı',
        });
        this.setState({ loading: false });
    }
    deleteComment = id => {
        this.setState({loading: true});
        axios({
            url: this.props.config.cdnUrl + 'comments/remove',
            method: 'POST',
            data: {
                token: this.props.config.token,
                commentId: id
            }
        }).then(res => {
            if(res.data.status === 200){
                if(res.data.success){
                    notification.success({
                        message: 'İşlem Başarılı',
                        description: 'Yorum başarıyla silindi.',
                    });
                    return this.getComments(this.state.pagination.current);
                }else{
                    this.error();
                }
            }else{
                this.error();
            }
        }).catch(() => {
            this.error();
        })
    }
    editComment = id => {
        this.setState({loading: true});
        axios({
            url: this.props.config.cdnUrl + 'comments/edit',
            method: 'POST',
            data: {
                token: this.props.config.token,
                commentId: id,
                approved: this.state.approved === 1 ? 0 : 1
            }
        }).then(res => {
            if(res.data.status === 200){
                if(res.data.success){
                    notification.success({
                        message: 'İşlem Başarılı',
                        description: 'Yorum başarıyla düzenlendi.',
                    });
                    return this.getComments();
                }else{
                    this.error();
                }
            }else{
                this.error();
            }
        }).catch(() => {
            this.error();
        })
    }
    showComment = comment => {
        this.setState({
            modal: {
                visible: true,
                data: comment
            }
        })
    }
    getComments = (page = 1) => {
        this.setState({loading: true});
        axios({
            url: this.props.config.cdnUrl + 'comments/getAll',
            method: 'POST',
            data: {
                token: this.props.config.token,
                page,
                approved: this.state.approved
            }
        }).then(res => {
            if(res.data.status === 200){
                if(res.data.success){
                    const comments = res.data['comments'].map(comment => {
                        return {
                            id: comment['id'],
                            key: comment['id'],
                            commentAuthor: comment['author'],
                            email: comment['email'] === 'empty' ? 'boş' : comment['email'],
                            pageTitle: comment['title'],
                            createTime: comment['createTime'],
                            reply: comment['reply'],
                            events: comment
                        };
                    });
                    this.setState({
                        loading: false,
                        data: comments,
                        pagination: {
                            ...this.state.pagination,
                            total: res.data.total
                        }
                    });
                }else{
                    const current = this.state.pagination.current;
                    if(res.data['msg'] === 'page is not exist' && current > 1){
                        this.setState({
                            pagination: {
                                ...this.state.pagination,
                                current: current - 1
                            }
                        });
                        return this.getComments(current - 1);
                    }else{
                        this.error();
                    }
                }
            }else{
                this.error();
            }
        }).catch(() => {
            this.error();
        })
    }
    changeCurrentPage = page => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                current: page
            }
        });
        this.getComments(page);
    }
    componentDidMount() {
        this.setState({
            pagination: {
                ...this.state.pagination,
                onChange: this.changeCurrentPage
            }
        })
        setTimeout(() => this.getComments(), 100);
    }
    render() {
        return (
            <div className={'comments-list'}>
                <PageHeader
                    className={'page-header'}
                    title={this.state.approved ? 'Onaylanmış Yorumlar' : 'Onaylanmamış Yorumlar'}
                />
                <div className={'page-content'}>
                    <Table
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        size={'middle'}
                        bordered={true}
                        columns={this.state.columns}
                        dataSource={this.state.data}
                    />
                </div>
                <Modal
                    width={'800px'}
                    visible={this.state.modal.visible}
                    footer={[]}
                    onCancel={() => {
                        this.setState({
                            modal: {
                                visible: false,
                                data: {}
                            }
                        })
                    }}
                >
                    <h3>{this.state.modal.data.title}</h3>
                    <div style={{
                        display: 'flex',
                        marginTop: '.5rem'
                    }}>
                        <Tag icon={<UserOutlined/>}>{this.state.modal.data.author}</Tag>
                        <Tag icon={<MailOutlined/>}>{this.state.modal.data.email}</Tag>
                        <span style={{flex: '1'}}/>
                        <Tag icon={<ClockCircleOutlined/>}>{this.state.modal.data.createTime}</Tag>
                    </div>
                    <Divider style={{margin: '.8rem 0'}}/>
                    <p dangerouslySetInnerHTML={{__html: this.state.modal.data.content}}/>
                </Modal>
            </div>
        );
    }
}

export default CommentList;