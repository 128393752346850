import {Button, Modal, notification, PageHeader, Popconfirm, Space, Table} from 'antd';
import axios from 'axios';
import {PictureOutlined} from '@ant-design/icons';
import {setPopup} from '../../store/actions/images';
import SchemeEditor from '../common-use/schemeEditor';
import { connect } from 'react-redux';
import '../../stylesheets/pages/pageList.scss';
import moment from 'moment';

class PageList extends SchemeEditor {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            loading: false,
            pageEditLoading: false,
            currentEditPageId: null,
            modalVisible: false,
            pagination: {
                current: 1,
                total: 10,
                pageSize: 10,
            },
            columns: [
                {
                    title: 'Sayfa ismi',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: 'Sayfa yolu',
                    dataIndex: 'path',
                    key: 'path',
                },
                {
                    title: 'Yorum sayısı',
                    dataIndex: 'amountOfComment',
                    key: 'amountOfComment',
                },
                {
                    title: 'Oluşturulma tarihi',
                    dataIndex: 'createTime',
                    key: 'createTime',
                    render: time => moment(time).format('D MMMM YYYY HH:mm:ss')
                },
                {
                    title: 'Olaylar',
                    dataIndex: 'events',
                    key: 'events',
                    width: '200px',
                    render: page => (
                        <Space size={'middle'}>
                            <Button
                                onClick={() => this.editPage(page)}
                                key={'1'}
                                size={'small'}
                            >
                                Sayfayı düzenle
                            </Button>
                            <Popconfirm
                                title={'Bu sayfayı silmek istediğinize emin misiniz?'}
                                okText={'Evet'}
                                cancelText={'Hayır'}
                                placement={'topRight'}
                                onConfirm={() => this.deletePage(page.id)}
                                key={'3'}
                            >
                                <Button
                                    type={'danger'}
                                    size={'small'}
                                >
                                    Sil
                                </Button>
                            </Popconfirm>
                        </Space>
                    ),
                }
            ],
            data: [],
            firstTouch: false,
            currentScheme: 'Bir şablon seç',
            schemes: [],
            popup: false,
            popupURL: null
        }
    }
    error = () => {
        notification.error({
            message: 'Bir Hata Oluştu!',
            description: 'database\'ye bağlanılamadı',
        });
        this.setState({ loading: false });
    }
    deletePage = id => {
        this.setState({ loading: true });
        axios({
            url: this.props.config.cdnUrl + 'pages/remove',
            method: 'POST',
            data: { token: this.props.config.token, pageId: id }
        })
            .then(res => {
                if(res.data.status === 200){
                    if(res.data.success){
                        notification.success({
                            message: 'İşlem Başarılı',
                            description: 'Sayfa başarıyla silindi.',
                        });
                        return this.getPages(this.state.pagination.current);
                    }else{
                        this.error();
                    }
                }else{
                    this.error();
                }
            })
            .catch(() => {
                this.error();
            })
    }
    editPage = (page) => {
        this.setState({
            ...page.state,
            components: this.state.components,
            modalVisible: true,
            currentEditPageId: page.id
        });
    }
    getPages = (page = 1) => {
        this.setState({ loading: true });
        axios({
            url: this.props.config.cdnUrl + 'pages/getAll',
            method: 'POST',
            data: {
                token: this.props.config.token,
                page: page
            }
        })
            .then(res => {
                if(res.data.status === 200){
                    if(res.data.success){
                        const pages = res.data['pages'].map(clientPage => {
                            return {
                                id: clientPage['id'],
                                key: clientPage['id'],
                                path: clientPage['path'],
                                amountOfComment: clientPage['amountOfComment'],
                                createTime: clientPage['createTime'],
                                title: clientPage['title'],
                                events: { ...clientPage }
                            };
                        });
                        this.setState({
                            loading: false,
                            data: pages,
                            pagination: {
                                ...this.state.pagination,
                                total: res.data.total
                            }
                        });
                    }else{
                        const current = this.state.pagination.current;
                        if(res.data['msg'] === 'page is not exist' && current > 1){
                            this.setState({
                                pagination: {
                                    ...this.state.pagination,
                                    current: current - 1
                                }
                            });
                            return this.getPages(current - 1);
                        }else{
                            this.error();
                        }
                    }
                }else{
                    this.error();
                }
            })
            .catch(() => {
                this.error();
            })
    }
    changeCurrentPage = page => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                current: page
            }
        });
        this.getPages(page);
    }
    pageError = () => {
        notification.error({
            message: 'Sayfa kaydedilemedi!',
            description: 'Teknik bir sorundan dolayı sayfa kaydedilemedi!'
        });
    }
    savePage = () => {
        const s = { ...this.state };

        const title = s.pageInfo.title;
        const metaImage = s.info.schemeImage;
        const description = s.info.schemeDescription;
        const coverImage = s.info.coverImage;
        const coverImageInfo = s.info.coverImageInfo;
        const children = s.children;
        const scheme = s.info.schemeNameForURL;
        const content = s[s.type];

        delete s['columns'];
        delete s['currentEditPageId'];
        delete s['data'];
        delete s['firstTouch'];
        delete s['loading'];
        delete s['modalVisible'];
        delete s['popup'];
        delete s['popupURL'];
        delete s['schemes'];
        delete s['pagination'];
        delete s['components'];

        // validation
        const [err, pageInfo] = this.checkPageInfo({ title, metaImage, description, coverImage, coverImageInfo });
        if(err){
            notification.error({
                message: 'Sayfa kaydedilemedi!',
                description: err
            });
        }else{
            let componentError = null;
            for (const child of children) {
                if(child.type === 'component'){
                    componentError = this.checkComponent(child);
                    if(componentError !== null){
                        break;
                    }
                }
            }
            if(componentError !== null){
                notification.error({
                    message: 'Sayfa kaydedilemedi!',
                    description: componentError
                });
            }else{
                this.setState({ pageEditLoading: true });
                const page = {
                    info: { ...pageInfo, tags: s.info.tags },
                    scheme: scheme,
                    content: content,
                    children: children
                };
                axios({
                    url: this.props.config.cdnUrl + 'pages/edit',
                    method: 'POST',
                    data: {
                        pageId: this.state.currentEditPageId,
                        token: this.props.config.token,
                        content: JSON.stringify(page),
                        state: JSON.stringify(s),
                        person: this.getPagePerson(children) === null ? 'null' : this.getPagePerson(children)
                    }
                })
                    .then(res => {
                        if(res.data.hasOwnProperty('status')){
                            if(res.data.status === 200){
                                if(res.data.success){
                                    notification.success({
                                        message: 'İşlem başarılı',
                                        description: 'Sayfanız başarıyla düzenlendi.'
                                    });
                                    this.getPages(this.state.pagination.current);
                                }else
                                    this.pageError();
                            }else
                                this.pageError();
                        }else
                            this.pageError();
                        this.setState({ pageEditLoading: false });
                    })
                    .catch(() => {
                        this.setState({ pageEditLoading: false });
                        this.pageError();
                    })
            }
        }
    }
    emptyChecker = (item) => {
        try{
            return item === null || item === undefined || item?.trim() === '';
        }catch (e) {
            return false;
        }
    }
    checkPageInfo = ({ title, metaImage, description, coverImage, coverImageInfo }) => {
        const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        if(title === null || title === undefined || title?.trim() === ''){
            return ['Sayfa başlığını boş bırakamazsınız!'];
        }else if(metaImage === null || metaImage === undefined || metaImage?.trim() === ''){
            return ['Sayfa görselini boş bırakamazsınız!'];
        }else if(description === null || description === undefined || description?.trim() === ''){
            return ['Sayfa açıklamasını boş bırakamazsınız!'];
        }else if(!(coverImage === null || coverImage === undefined || coverImage?.trim() === '')){
            if(coverImage.match(urlRegex) === null){
                return ['Sayfa kapak görseli geçersiz biçimde!'];
            }
        }else if(metaImage.match(urlRegex) === null){
            return ['Sayfa görseli geçersiz biçimde!'];
        }
        return [ false, { title, titleForURL:  this.toSeoUrl(title), metaImage, description, coverImage,
            coverImageInfo: (coverImageInfo === null || coverImageInfo === undefined || coverImageInfo?.trim() === '') ? null : coverImageInfo
        }];
    }
    componentDidMount() {
        this.setState({
            pagination: {
                ...this.state.pagination,
                onChange: this.changeCurrentPage
            }
        })
        setTimeout(() => {
            this.getPages();
            this.getComponents();
        }, 100);
    }
    render() {
        return (
            <div className={'page-list'}>
                <PageHeader
                    className={'page-header'}
                    title={'Sayfa listesi'}
                />
                <div className={'page-content'}>
                    <Table
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        size={'middle'}
                        bordered={true}
                        columns={this.state.columns}
                        dataSource={this.state.data}
                    />
                </div>
                <Modal
                    style={{ top: 30 }}
                    width={'min(98vw,80rem)'}
                    visible={this.state.modalVisible}
                    title={'Sayfayı düzenle'}
                    onCancel={() => this.setState({modalVisible: false})}
                    footer={[
                        <div key={'footer'} style={{ width: '100%', display: 'flex', flexWrap: 'wrap'}}>
                            <div style={{flex: '1'}}/>
                            <Button onClick={() => this.setState({modalVisible: false})} style={{
                                marginRight: '.5rem',
                                marginTop: '.3rem'
                            }}>İptal</Button>,
                            <Button type={'primary'} onClick={this.savePage} loading={this.state.pageEditLoading} style={{
                                marginLeft: '0',
                                marginTop: '.3rem'
                            }}>Düzenlemeyi kaydet</Button>
                        </div>
                    ]}
                >
                    <div className={'page-edit-area'}>
                        {this.createPageInfo(this.state.info.schemeNameForURL)}
                        {this.createContent()}
                    </div>
                </Modal>
                <Button
                    className={'select-image-button'}
                    icon={<PictureOutlined/>}
                    onClick={() => this.props.dispatch(setPopup({open: true}))}
                >
                    Görsel Seç
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { config: state.config }
}
const mapDispatchToProps = (dispatch) => ({
    setPopup,
    dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(PageList);
