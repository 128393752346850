import SchemeEditor from '../common-use/schemeEditor';
import {Button, notification, PageHeader} from 'antd';
import '../../stylesheets/pages/newScheme.scss';
import {connect} from 'react-redux';
import {setPopup} from '../../store/actions/images';
import {PictureOutlined} from '@ant-design/icons';
import axios from 'axios';


class NewScheme extends  SchemeEditor{
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            loading: false
        }
    }
    saveScheme = () => {
        const [error, info] = this.checkInfo(this.state.info);
        if(error){
            notification.error({
                message: 'Şablon kaydedilemedi!',
                description: error
            });
        }else{
            this.setState({ loading: true });
            const content = {
                type: this.state.type,
                info: info,
                content: {
                    scheme: this.state[this.state.type],
                    children: this.state.children
                }
            }
            axios({
                url: this.props.config.cdnUrl + 'schemes/set',
                method: 'POST',
                data: {
                    token: this.props.config.token,
                    content: JSON.stringify(content)
                }
            })
                .then(res => {
                    if(res.data.status === 200){
                        if(res.data.success){
                            notification.success({
                                message: 'Şablon kaydedildi!',
                                description: 'Şablonunuz başarıyla oluşturuldu!'
                            });
                            setTimeout(() => { window.location.reload(); }, 2000);
                        }else{
                            if(res.data['msg'] === 'this name is exist'){
                                notification.error({
                                    message: 'Şablon kaydedilemedi!',
                                    description: 'Bu şablon ismi kullanımda!'
                                });
                            }else{
                                notification.error({
                                    message: 'Şablon kaydedilemedi!',
                                    description: 'Teknik bir aksaklıktan dolayı şablon kaydedilemedi!'
                                });
                            }
                        }
                    }
                    this.setState({ loading: false });
                })
                .catch(() => {
                    notification.error({
                        message: 'Şablon kaydedilemedi!',
                        description: 'Teknik bir aksaklıktan dolayı şablon kaydedilemedi!'
                    });
                    this.setState({ loading: false });
                });
        }
    }

    render() {
        return (
            <div className={'new-scheme'}>
                <PageHeader
                    className={'page-header'}
                    title={'Yeni şablon ekle'}
                />
                <div className={'page-content'}>
                    {this.createSchemeInfo()}
                    {this.createSchemeTypeSelector()}
                    {this.createContent()}
                    <div style={{
                        margin: '2rem 0 3rem 0'
                    }}>
                        <Button
                            type={'primary'}
                            onClick={this.saveScheme}
                            loading={this.state.loading}
                        >
                            Şablonu kaydet
                        </Button>
                    </div>
                </div>
                <Button
                    className={'select-image-button'}
                    icon={<PictureOutlined/>}
                    onClick={() => this.props.dispatch(setPopup({open: true}))}
                >
                    Görsel Seç
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {config: state.config}
}

const mapDispatchToProps = (dispatch) => ({
    setPopup,
    dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(NewScheme);
