import {Component} from 'react';
import {PageHeader} from 'antd';
import '../../stylesheets/home/home.scss';

class Home extends Component {
    state = {};
    render() {
        return (
            <div className={'home-page'}>
                <PageHeader
                    className={'page-header'}
                    title={'Anasayfa'}
                />
                <div className={'page-content'}>
                    birçok şey ile uğraşırken kontrol panelinin ana sayfasına giriş kayıtlarını falan gösteren bi yer
                    yapmaya vakit bulamadım. çok da önemli olmadığından önümüzdeki günlere erteliyorum bu işi
                </div>
            </div>
        );
    }
}

export default Home;
