import {CREATE_IMAGE_LIST, SET_PAGE, SET_LOADING, SET_POPUP} from '../types';

const defaultState = {
    popup:{
        open: false
    },
    gallery: {
        currentPage: 1,
        loading: false,
        total: [],
        current: []
    },
};

const ImagesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_PAGE:
            const total = [...state.gallery.total];
            const prev = total.slice(0,(action.page-1) * 21);
            const next = total.slice(action.page * 21, total.length);
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    currentPage: action.page,
                    current: [...action.images],
                    total: prev.concat(action.images, next)
                }
            };
        case CREATE_IMAGE_LIST:
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    total: [...action.payload]
                }
            };
        case SET_LOADING:
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    loading: action.payload
                }
            };
        case SET_POPUP:
            return {
                ...state,
                popup: {
                    ...state.popup,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export default ImagesReducer;