import SchemeEditor from '../common-use/schemeEditor';
import {Button, message, Modal, notification, PageHeader, Select} from 'antd';
import '../../stylesheets/pages/newPage.scss';
import {connect} from 'react-redux';
import {setPopup} from '../../store/actions/images';
import {LinkOutlined, PictureOutlined} from '@ant-design/icons';
import axios from 'axios';

const {Option} = Select;


class NewPage extends SchemeEditor{
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            loading: false,
            firstTouch: false,
            currentScheme: 'Bir şablon seç',
            schemes: [],
            popup: false,
            popupURL: null
        }
    }
    getSchemes = () => {
        let page = 1;
        const schemes = [];
        const getSchemesAsync = async () => {
            try {
                const res = await axios({
                    url: this.props.config.cdnUrl + 'schemes/getAll',
                    method: 'POST',
                    data: { token: this.props.config.token, page }
                });
                if(res.data.status === 200){
                    if(res.data.success){
                        const _schemes = [ ...res.data['schemes'] ];
                        for (const scheme of _schemes) {
                            schemes.push(scheme);
                        }
                        page += 1;
                        return getSchemesAsync();
                    }else{
                        this.setState({ schemes });
                    }
                }
            } catch (err) {
                console.log(err);
            }
        };
        getSchemesAsync().then(r => r);
    }
    setScheme = (id) => {
        const scheme = this.state.schemes.filter(scheme => scheme.id === id)[0];
        this.setState({
            firstTouch: true,
            currentScheme: scheme['id'],
            info: {
                schemeName: scheme['name'],
                schemeNameForURL: scheme['nameForURL'],
                schemeImage: scheme['metaImage'],
                schemeDescription: scheme['description'],
                coverImage: scheme['coverImage'],
                coverImageInfo: scheme['coverImageInfo'],
                tags: scheme['tags']
            },
            type: scheme.type,
            children: [ ...scheme.content.children ],
            [scheme.type]: [ ...scheme.content.scheme ]
        });
    }
    error = () => {
        notification.error({
            message: 'Sayfa kaydedilemedi!',
            description: 'Teknik bir sorundan dolayı sayfa kaydedilemedi!'
        });
    }
    savePage = () => {
        const s = { ...this.state };
        const title = s.pageInfo.title;
        const metaImage = s.info.schemeImage;
        const description = s.info.schemeDescription;
        const coverImage = s.info.coverImage;
        const coverImageInfo = s.info.coverImageInfo;
        const children = s.children;
        const scheme = s.info.schemeNameForURL;
        const content = s[s.type];
        // validation
        const [err, pageInfo] = this.checkPageInfo({ title, metaImage, description, coverImage, coverImageInfo });
        if(err){
            notification.error({
                message: 'Sayfa kaydedilemedi!',
                description: err
            });
        }else{
            let componentError = null;
            for (const child of children) {
                if(child.type === 'component'){
                    componentError = this.checkComponent(child);
                    if(componentError !== null){
                        break;
                    }
                }
            }
            if(componentError !== null){
                notification.error({
                    message: 'Sayfa kaydedilemedi!',
                    description: componentError
                });
            }else{
                const page = {
                    info: { ...pageInfo, tags: s.info.tags },
                    scheme: scheme,
                    content: content,
                    children: children
                };
                this.setState({ loading: true });
                axios({
                    url: this.props.config.cdnUrl + 'pages/set',
                    method: 'POST',
                    data: {
                        token: this.props.config.token,
                        content: JSON.stringify(page),
                        state: JSON.stringify(s),
                        person: this.getPagePerson(children) === null ? 'null' : this.getPagePerson(children)
                    }
                })
                    .then(res => {
                        if(res.data.hasOwnProperty('status')){
                            if(res.data.status === 200){
                                if(res.data.success){
                                    this.setState({
                                        popup: true,
                                        popupURL: this.props.config.mainUrl + res.data.path.slice(1)
                                    })
                                }else
                                    this.error();
                            }else
                                this.error();
                        }else
                            this.error();
                        this.setState({ loading: false });
                    })
                    .catch(() => {
                        this.error();
                        this.setState({ loading: false });
                    })
            }
        }
    }
    emptyChecker = (item) => {
        return item === null || item === undefined || item?.trim() === '';
    }
    checkPageInfo = ({ title, metaImage, description, coverImage, coverImageInfo }) => {
        const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        if(title === null || title === undefined || title?.trim() === ''){
            return ['Sayfa başlığını boş bırakamazsınız!'];
        }else if(metaImage === null || metaImage === undefined || metaImage?.trim() === ''){
            return ['Sayfa görselini boş bırakamazsınız!'];
        }else if(description === null || description === undefined || description?.trim() === ''){
            return ['Sayfa açıklamasını boş bırakamazsınız!'];
        }else if(!(coverImage === null || coverImage === undefined || coverImage?.trim() === '')){
            if(coverImage.match(urlRegex) === null){
                return ['Sayfa kapak görseli geçersiz biçimde!'];
            }
        }else if(metaImage.match(urlRegex) === null){
            return ['Sayfa görseli geçersiz biçimde!'];
        }
        return [ false, { title, titleForURL:  this.toSeoUrl(title), metaImage, description, coverImage,
            coverImageInfo: (coverImageInfo === null || coverImageInfo === undefined || coverImageInfo?.trim() === '') ? null : coverImageInfo
        }];
    }
    copyToClipboard = str => {
        try{
            navigator.clipboard.writeText(str).then(r => r);
        }catch (e) {
            const el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    };
    componentDidMount() {
        this.getComponents();
        this.getSchemes();
    }
    render() {
        let schemeNameForURL = 'sablon-ismi';
        const schemes = this.state.schemes.filter(scheme => scheme.id === this.state.currentScheme);
        if(schemes.length > 0){
            schemeNameForURL = schemes[0]['nameForURL'];
        }
        return (
            <div className={'new-page'}>
                <PageHeader
                    className={'page-header'}
                    title={'Yeni sayfa ekle'}
                />
                <div className={'page-content'}>
                    <Select
                        value={ this.state.currentScheme }
                        style={{ width: '200px', marginBottom: '1rem' }}
                        onChange={value => this.setScheme(value)}
                    >
                        {this.state.schemes.map(scheme => {
                            return(
                                <Option value={scheme.id} key={scheme.id}>{scheme.name}</Option>
                            );
                        })}
                    </Select>
                    {this.state.firstTouch && this.createPageInfo(schemeNameForURL)}
                    {this.state.firstTouch && this.createContent()}
                    <div style={{ margin: '2rem 0 3rem 0', display: this.state.firstTouch ? 'block' : 'none' }}>
                        <Button
                            type={'primary'}
                            onClick={this.savePage}
                            loading={this.state.loading}
                        >Sayfayı kaydet</Button>
                    </div>
                </div>
                <Modal
                    visible={this.state.popup}
                    title={'Sayfanız başarıyla kaydedildi'}
                    footer={[
                        <Button onClick={() => {
                            this.setState({popup: false});
                            window.location.reload();
                        }}>Tamam</Button>
                    ]}
                    onCancel={() => {
                        this.setState({popup: false});
                        window.location.reload();
                    }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '1rem'
                    }}>
                        <div style={{
                            width: '2rem',
                            height: '2rem',
                            borderRadius: '2rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#ffffff',
                            backgroundColor: '#4285f4',
                            marginRight: '1rem'
                        }}>
                            <LinkOutlined/>
                        </div>
                        <h3 style={{margin: '0'}}>Bağlantıyı Alın</h3>
                        <div style={{flex: '1'}}/>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            backgroundColor: '#e6f4ea',
                            color: '#1e8e3e',
                            borderRadius: '.5rem',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            overflow: 'unset',
                            padding: '.5rem 1rem',
                            flex: '1',
                            marginRight: '.5rem'
                        }}>
                            {this.state.popupURL}
                        </div>
                        <Button
                            type={'text'}
                            style={{
                                borderRadius: '.5rem',
                                fontWeight: '600',
                                color: '#1a73e8'
                            }}
                            onClick={() => {
                                this.copyToClipboard(this.state.popupURL);
                                message.success('Bağlantı kopyalandı').then(r => r);
                            }}
                        >
                            Bağlantıyı kopyala
                        </Button>
                    </div>
                    <div style={{
                        marginTop: '.5rem',
                        padding: '.5rem'
                    }}>
                        Bu bağlantıyı kullanarak sayfanızı görüntüleyebilir ve paylaşabilirsiniz.
                    </div>
                </Modal>
                <Button
                    className={'select-image-button'}
                    icon={<PictureOutlined/>}
                    onClick={() => this.props.dispatch(setPopup({open: true}))}
                >
                    Görsel Seç
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {config: state.config}
}

const mapDispatchToProps = (dispatch) => ({
    setPopup,
    dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(NewPage);
