import React from 'react';

function Error() {
    return (
        <div>
            <h1>yanlış bir yerlere tıkladın gibi duruyor</h1>
        </div>
    );
}

export default Error;
