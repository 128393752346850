import {Component} from 'react';
import {Button, Input, Select, Tag, Typography} from 'antd';
import SCHEME_1 from '../../images/scheme_1.svg';
import SCHEME_5 from '../../images/scheme_5.svg';
import SCHEME_2 from '../../images/scheme_2.svg';
import SCHEME_3 from '../../images/scheme_3.svg';
import SCHEME_4 from '../../images/scheme_4.svg';
import {
    AlignCenterOutlined,
    AlignLeftOutlined,
    AlignRightOutlined,
    ColumnHeightOutlined,
    ColumnWidthOutlined,
    DeleteOutlined,
    MenuOutlined
} from '@ant-design/icons';
import axios from 'axios';
import EditableComponent from '../pages/editableComponent';

const { TextArea } = Input;
const { Title } = Typography;
const { Option, OptGroup } = Select;

class SchemeEditor extends Component {
    state = {
        info: {
            schemeName: null,
            schemeNameForURL: null,
            schemeImage: null,
            schemeDescription: null,
            coverImage: null,
            coverImageInfo: null,
            tags: []
        },
        pageInfo: {
            title: null,
        },
        components: [],
        type: 'schemeType1',
        children: [
            {
                key: 'icerik-2-type-1',
                type: 'box',
                title: 'İçerik 2',
                isRemovable: false,
                align: 'left',
                location: 'vertical',
                appendNewBox: false,
                width: 400,
                children: []
            },
            {
                key: 'icerik-3-type-1',
                type: 'box',
                title: 'İçerik 3',
                isRemovable: false,
                align: 'left',
                location: 'vertical',
                appendNewBox: false,
                width: null,
                children: []
            },
            {
                key: 'icerik-1-type-5',
                type: 'box',
                title: 'İçerik 1',
                isRemovable: false,
                align: 'space-between',
                location: 'vertical',
                appendNewBox: false,
                width: 400,
                children: []
            },
            {
                key: 'icerik-2-type-5',
                type: 'box',
                title: 'İçerik 2',
                isRemovable: false,
                align: 'space-between',
                location: 'vertical',
                appendNewBox: false,
                width: null,
                children: []
            }
        ],
        schemeType1: [
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik 1',
                isRemovable: false,
                align: 'space-between',
                location: 'vertical',
                appendNewBox: true,
                width: null,
                children: []
            },
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik',
                isRemovable: false,
                align: 'space-between',
                location: 'horizontal',
                appendNewBox: true,
                width: null,
                children: ['icerik-2-type-1','icerik-3-type-1']
            },
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik 4',
                isRemovable: false,
                align: 'space-between',
                location: 'horizontal',
                appendNewBox: true,
                width: null,
                children: []
            }
        ],
        schemeType2: [
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik 1',
                isRemovable: false,
                align: 'left',
                location: 'vertical',
                appendNewBox: true,
                width: null,
                children: []
            },
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik 2',
                isRemovable: false,
                align: 'space-between',
                location: 'vertical',
                appendNewBox: true,
                width: null,
                children: []
            },
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik 3',
                isRemovable: false,
                align: 'space-between',
                location: 'horizontal',
                appendNewBox: true,
                width: null,
                children: []
            }
        ],
        schemeType3: [
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik 1',
                isRemovable: false,
                align: 'left',
                location: 'vertical',
                appendNewBox: true,
                width: null,
                children: []
            },
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik 2',
                isRemovable: false,
                align: 'space-between',
                location: 'horizontal',
                appendNewBox: true,
                width: null,
                children: []
            }
        ],
        schemeType4: [
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik',
                isRemovable: false,
                align: 'left',
                location: 'vertical',
                appendNewBox: true,
                width: null,
                children: []
            }
        ],
        schemeType5: [
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik',
                isRemovable: false,
                align: 'space-between',
                location: 'horizontal',
                appendNewBox: true,
                width: null,
                children: ['icerik-1-type-5', 'icerik-2-type-5']
            },
            {
                key: Math.random(),
                type: 'box',
                title: 'İçerik 2',
                isRemovable: false,
                align: 'space-between',
                location: 'horizontal',
                appendNewBox: true,
                width: null,
                children: []
            }
        ],
    };
    checkComponent = (component) => {
        const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
        const c = component.content[component.componentType];
        if(component.componentType === 'title'){
            if(this.emptyChecker(c.value)){
                return 'Başlık bileşenini boş bırakamazsınız!';
            }
        }
        else if(component.componentType === 'bigCard'){
            if(this.emptyChecker(c.value)){
                return 'Büyük kart bileşeninde kartta görünecek metni boş bırakamazsınız!';
            }
        }
        else if(component.componentType === 'button'){
            if(this.emptyChecker(c.text)){
                return 'Buton bileşeninde butonda görünecek metni boş bırakamazsınız!';
            }else if(this.emptyChecker(c.url)){
                return 'Buton bileşeninde yönlendirilecek URL\'yi boş bırakamazsınız!';
            }else{
                if(c.url.match(urlRegex) === null){
                    return 'Buton bileşeninde yönlendirilecek URL geçersiz biçimde!';
                }
            }
        }
        else if(component.componentType === 'slider'){
            for (const slide of c.list) {
                if(this.emptyChecker(slide.image)){
                    return 'Slayt bileşeninde görseli boş bırakamazsınız!';
                }else if(slide.image.match(urlRegex) === null){
                    return 'Slayt bileşeninde görsel geçersiz biçimde!';
                }else if(!this.emptyChecker(slide.URL)){
                    if(slide.URL.match(urlRegex) === null){
                        return 'Slayt bileşeninde yönlendirilecek URL geçersiz biçimde!';
                    }
                }
            }
        }
        else if(component.componentType === 'breadcrumb'){
            for (const item of c.list) {
                if(this.emptyChecker(item.title)){
                    return 'Gezinti menüsünde menü başlığını boş bırakamazsınız!';
                }else if(!this.emptyChecker(item.URL)){
                    if(item.URL.match(urlRegex) === null){
                        return 'Gezinti menüsünde yönlendirilecek URL geçersiz biçimde!';
                    }
                }
            }
        }
        else if(component.componentType === 'calendar'){
            for (const item of c.list) {
                if(this.emptyChecker(item.title)){
                    return 'Takvim başlığını boş bırakamazsınız!';
                }
                else if(this.emptyChecker(item.date)){
                    return 'Takvim tarihini boş bırakamazsınız!';
                }
                else if(!this.emptyChecker(item.URL)){
                    if(item.URL.match(urlRegex) === null){
                        return 'Takvimde yönlendirilecek URL geçersiz biçimde!';
                    }
                }
            }
        }
        else if(component.componentType === 'cards'){
            for (const item of c.list) {
                if(this.emptyChecker(item.title)){
                    return 'Kart başlığını boş bırakamazsınız!';
                }
                else if(this.emptyChecker(item.image)){
                    return 'Kart görselini boş bırakamazsınız!';
                }
                else if(item.image.match(urlRegex) === null){
                    return 'Kart görsel URL\'si geçersiz biçimde!';
                }
                else if(!this.emptyChecker(item.URL)){
                    if(item.URL.match(urlRegex) === null){
                        return 'Kartta yönlendirilecek URL geçersiz biçimde!';
                    }
                }
            }
        }
        else if(component.componentType === 'alertBox'){
            if(this.emptyChecker(c.content)){
                return 'Uyarı kutusu içeriğini boş bırakamazsınız!';
            }
        }
        else if(component.componentType === 'pageInfoBox'){
            if(this.emptyChecker(c.person) || c.person === 'kisi-sec'){
                return 'Sayfa bilgisi kutusunda bir kişi seçmelisiniz!';
            }
        }
        else if(component.componentType === 'contentCorner'){
            if(this.emptyChecker(c.title)){
                return 'İçerik köşesinin başlığını boş bırakamazsınız!';
            }
            for (const item of c.list) {
                if(this.emptyChecker(item.text)){
                    return 'İçerik köşesinde içerik başlığı boş bırakamazsınız!';
                }
                else if(this.emptyChecker(item.url)){
                    return 'İçerik köşesinde içerik URL\'sini boş bırakamazsınız!';
                }
                else if(item.url.match(urlRegex) === null){
                    return 'İçerik köşesinde içerik URL\'si geçersiz biçimde!';
                }
            }
        }
        else if(component.componentType === 'embedYoutube'){
            if(this.emptyChecker(c.url)){
                return 'Youtube videosunda URL\'yi boş bırakamazsınız!';
            }
            else if(c.url.match(urlRegex) === null){
                return 'Youtube videosundaki URL geçersiz biçimde!';
            }
        }
        else if(component.componentType === 'image'){
            if(this.emptyChecker(c.url)){
                return 'Görsel bileşeninde görsel URL\'sini boş bırakamazsınız!';
            }
            else if(c.url.match(urlRegex) === null){
                return 'Görsel bileşenindeki görsel URL\'si geçersiz biçimde!';
            }
        }
        else if(component.componentType === 'gallery'){
            for (const item of c.list) {
                if(this.emptyChecker(item.url)){
                    return 'Galeride görsel URL\'sini boş bırakamazsınız!';
                }
                else if(item.url.match(urlRegex) === null){
                    return 'Galerideki görsel URL\'si geçersiz biçimde!';
                }
            }
        }
        else if(component.componentType === 'personBox'){
            if(this.emptyChecker(c.person) || c.person === 'kisi-sec'){
                return 'Kişi kutusunda bir kişi seçmelisiniz!';
            }
        }
        else if(component.componentType === 'fileList'){
            for (const item of c.list) {
                if( item.title === null ){
                    return 'Dosya adını boş bırakamazsınız!';
                }
                else if( item.url === null ){
                    return 'Bir dosya yüklemelisiniz!';
                }
                else{
                    const name = item.title.trim();
                    if(name.length < 3){
                        return 'Dosya adınız en az 3 karakter olmalıdır!';
                    }
                }
            }
        }
        else if(component.componentType === 'contentListing'){
            if( c.list.length === 0 ){
                return 'İçerik listelemede en az 1 madde seçmelisiniz!';
            }
        }
        return null;
    }
    clearState = () => {
        const children = this.state.children.filter(item => {
            return ['icerik-1-type-5', 'icerik-2-type-5','icerik-2-type-1','icerik-3-type-1'].includes(item.key);
        });
        this.setState({
            children: children,
            schemeType1: this.state.schemeType1.map((box, index) => {
                if(box.hasOwnProperty('children')){
                    if(index === 1)
                        box.children = ['icerik-2-type-1','icerik-3-type-1'];
                    else
                        box.children = [];
                }
                return box;
            }),
            schemeType2: this.state.schemeType2.map(box => {
                if(box.hasOwnProperty('children')){
                    box.children = [];
                }
                return box;
            }),
            schemeType3: this.state.schemeType3.map(box => {
                if(box.hasOwnProperty('children')){
                    box.children = [];
                }
                return box;
            }),
            schemeType4: this.state.schemeType4.map(box => {
                if(box.hasOwnProperty('children')){
                    box.children = [];
                }
                return box;
            }),
            schemeType5: this.state.schemeType5.map((box, index) => {
                if(box.hasOwnProperty('children')){
                    if(index === 0)
                        box.children = ['icerik-1-type-5', 'icerik-2-type-5'];
                    else
                        box.children = [];
                }
                return box;
            }),
        })
    }
    checkInfo = (_info) => {
        const info = { ..._info };
        if(info.schemeName === null){
            return [ 'Şablon ismini boş bırakamazsınız!', {} ];
        }else if(info.schemeName.trim() === ''){
            return [ 'Şablon ismini boş bırakamazsınız!', {} ];
        }else{
            info.schemeNameForURL = this.toSeoUrl(info.schemeName);
            return [ null, info ];
        }
    }
    toSeoUrl = (text) => {
        const trMap = {
            'çÇ':'c',
            'ğĞ':'g',
            'şŞ':'s',
            'üÜ':'u',
            'ıİ':'i',
            'öÖ':'o'
        };
        for(let key in trMap) {
            text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
        }
        return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
            .replace(/\s/gi, "-") // convert spaces to dashes
            .replace(/[-]+/gi, "-") // trim repeated dashes
            .toLowerCase();
    }
    createSchemeInfo = () => {
        return(
            <div className={'scheme-info'}>
                <Title level={4}>Şablon ayarları</Title>
                <Input
                    value={this.state.info.schemeName}
                    onChange={e => this.setState({ info: { ...this.state.info, schemeName: e.target.value } })}
                    addonBefore={'Şablon ismi'}
                    spellCheck={false}
                />
                <small>* Şablon ismi benzersiz olmalıdır ve bu ismin site linklerinde görüneceği göz önünde bulunudurulmalıdır.</small>
                <small>* Linkler <b>{this.props.config.mainUrl}sablon-ismi/sayfa-basligi-12345</b> şeklinde görünecektir.</small>
                <Input
                    value={this.state.info.schemeImage}
                    onChange={e => this.setState({ info: { ...this.state.info, schemeImage: e.target.value } })}
                    addonBefore={'Şablon görseli URL\'si'}
                    spellCheck={false}
                />
                <small>* Bu görsel sayfanız paylaşıldığında whatsapp, telegram, twitter gibi platformlarda linke tıklamadan önce görünen görsel olacaktır.</small>
                <small>* Şablondan bağımsız bir şekilde sayfalara da bu görsellerden ekleyebilirsiniz.</small>
                <TextArea
                    showCount={true}
                    maxLength={400}
                    value={this.state.info.schemeDescription}
                    onChange={e => this.setState({ info: { ...this.state.info, schemeDescription: e.target.value } })}
                    rows={3}
                    placeholder={'Şema açıklaması'}
                    spellCheck={false}
                />
                <small>* Bu açıklama arama motoru sonuçlarınızda çıkacaktır.</small>
                <Input
                    value={this.state.info.coverImage}
                    onChange={e => this.setState({ info: { ...this.state.info, coverImage: e.target.value } })}
                    addonBefore={'Kapak görseli URL\'si'}
                    spellCheck={false}
                />
                <small>* Bu görsel sayfanızın girişinde büyük bir şekilde yer alacaktır. İsteğe bağlıdır, boş bırakabilirsiniz.</small>
                <Input
                    defaultValue={this.state.info.coverImageInfo}
                    onBlur={e => this.setState({ info: { ...this.state.info, coverImageInfo: e.target.value } })}
                    addonBefore={'Kapak görseli açıklaması'}
                    spellCheck={false}
                />
                <small>* Bu açıklama kapak görselinin altında yer alacaktır. Genellikle görsele kaynak gösterilirken kullanılır. İsteğe bağlıdır.</small>
                <Select
                    placeholder={'Etiketler'}
                    style={{ marginTop: '.5rem' }}
                    mode={'tags'}
                    open={false}
                    value={this.state.info.tags}
                    tagRender={props => <Tag closable={false}>{props.label}</Tag>}
                    onChange={list => this.setState({ info: { ...this.state.info, tags: list } })}
                />
            </div>
        );
    }
    createPageInfo = (schemeNameForURL) => {
        return(
            <div className={'page-info'}>
                <Title level={4}>Sayfa ayarları</Title>
                <Input
                    value={this.state.pageInfo.title}
                    onChange={e => this.setState({ pageInfo: { ...this.state.pageInfo, title: e.target.value } })}
                    addonBefore={'Sayfa başlığı'}
                    spellCheck={false}
                />
                <small>* Bu başlık sekmede ve linkde görünecek. Bunu göz önünde bulundurarak bir başlık seçin.</small>
                <small>* Linkler <b>{this.props.config.mainUrl}{schemeNameForURL}/sayfa-basligi-12345</b> şeklinde görünecektir.</small>
                <Input
                    value={this.state.info.schemeImage}
                    onChange={e => this.setState({ info: { ...this.state.info, schemeImage: e.target.value } })}
                    addonBefore={'Sayfa görseli URL\'si'}
                    spellCheck={false}
                />
                <small>* Bu görsel sayfanız paylaşıldığında whatsapp, telegram, twitter gibi platformlarda linke tıklamadan önce görünen görsel olacaktır.</small>
                <TextArea
                    showCount={true}
                    maxLength={400}
                    value={this.state.info.schemeDescription}
                    onChange={e => this.setState({ info: { ...this.state.info, schemeDescription: e.target.value } })}
                    rows={3}
                    placeholder={'Sayfa açıklaması'}
                    spellCheck={false}
                />
                <small>* Bu açıklama arama motoru sonuçlarınızda çıkacaktır.</small>
                <Input
                    value={this.state.info.coverImage}
                    onChange={e => this.setState({ info: { ...this.state.info, coverImage: e.target.value } })}
                    addonBefore={'Kapak görseli URL\'si'}
                    spellCheck={false}
                />
                <small>* Bu görsel sayfanızın girişinde büyük bir şekilde yer alacaktır. İsteğe bağlıdır, boş bırakabilirsiniz.</small>
                <Input
                    defaultValue={this.state.info.coverImageInfo}
                    onBlur={e => this.setState({ info: { ...this.state.info, coverImageInfo: e.target.value } })}
                    addonBefore={'Kapak görseli açıklaması'}
                    spellCheck={false}
                />
                <small>* Bu açıklama kapak görselinin altında yer alacaktır. Genellikle görsele kaynak gösterilirken kullanılır. İsteğe bağlıdır.</small>
                <Select
                    placeholder={'Etiketler'}
                    style={{ marginTop: '.5rem' }}
                    mode={'tags'}
                    open={false}
                    value={this.state.info.tags}
                    tagRender={props => <Tag closable={false}>{props.label}</Tag>}
                    onChange={list => this.setState({ info: { ...this.state.info, tags: list } })}
                />
            </div>
        );
    }
    createSchemeInfoWithoutName = () => {
        return(
            <div className={'scheme-info'}>
                <Title level={4}>Şablon ayarları</Title>
                <Input
                    value={this.state.info.schemeImage}
                    onChange={e => this.setState({ info: { ...this.state.info, schemeImage: e.target.value } })}
                    addonBefore={'Şablon görseli URL\'si'}
                    spellCheck={false}
                />
                <small>* Bu görsel sayfanız paylaşıldığında whatsapp, telegram, twitter gibi platformlarda linke tıklamadan önce görünen görsel olacaktır.</small>
                <small>* Şablondan bağımsız bir şekilde sayfalara da bu görsellerden ekleyebilirsiniz.</small>
                <TextArea
                    showCount={true}
                    maxLength={400}
                    value={this.state.info.schemeDescription}
                    onChange={e => this.setState({ info: { ...this.state.info, schemeDescription: e.target.value } })}
                    rows={3}
                    placeholder={'Şema açıklaması'}
                    spellCheck={false}
                />
                <small>* Bu açıklama arama motoru sonuçlarınızda çıkacaktır.</small>
                <Input
                    value={this.state.info.coverImage}
                    onChange={e => this.setState({ info: { ...this.state.info, coverImage: e.target.value } })}
                    addonBefore={'Kapak görseli URL\'si'}
                    spellCheck={false}
                />
                <small>* Bu görsel sayfanızın girişinde büyük bir şekilde yer alacaktır. İsteğe bağlıdır, boş bırakabilirsiniz.</small>
                <Input
                    defaultValue={this.state.info.coverImageInfo}
                    onBlur={e => this.setState({ info: { ...this.state.info, coverImageInfo: e.target.value } })}
                    addonBefore={'Kapak görseli açıklaması'}
                    spellCheck={false}
                />
                <small>* Bu açıklama kapak görselinin altında yer alacaktır. Genellikle görsele kaynak gösterilirken kullanılır. İsteğe bağlıdır.</small>
                <Select
                    placeholder={'Etiketler'}
                    style={{ marginTop: '.5rem' }}
                    mode={'tags'}
                    open={false}
                    value={this.state.info.tags}
                    tagRender={props => <Tag closable={false}>{props.label}</Tag>}
                    onChange={list => this.setState({ info: { ...this.state.info, tags: list } })}
                />
            </div>
        );
    }
    createSchemeTypeSelector = () => {
        return(
            <div>
                <Title level={4}>Şablon tipi</Title>
                <div className={'schemes'}>
                    <img
                        src={SCHEME_1}
                        alt={(this.state.type === 'schemeType1').toString()}
                        onClick={() => {
                            this.setState({type: 'schemeType1'});
                            this.clearState();
                        }}
                    />
                    <img
                        src={SCHEME_5}
                        alt={(this.state.type === 'schemeType5').toString()}
                        onClick={() => {
                            this.setState({type: 'schemeType5'});
                            this.clearState();
                        }}
                    />
                    <img
                        src={SCHEME_2}
                        alt={(this.state.type === 'schemeType2').toString()}
                        onClick={() => {
                            this.setState({type: 'schemeType2'});
                            this.clearState();
                        }}
                    />
                    <img
                        src={SCHEME_3}
                        alt={(this.state.type === 'schemeType3').toString()}
                        onClick={() => {
                            this.setState({type: 'schemeType3'});
                            this.clearState();
                        }}
                    />
                    <img
                        src={SCHEME_4}
                        alt={(this.state.type === 'schemeType4').toString()}
                        onClick={() => {
                            this.setState({type: 'schemeType4'});
                            this.clearState();
                        }}
                    />
                </div>
            </div>
        )
    }
    createContent = () => {
        return(
            <div className={'components'}>
                <Title level={4}>İçerik</Title>
                {this.state[this.state.type].map(content => {
                    return this.createContentBox({ ...content });
                })}
            </div>
        );
    }
    getAllBoxes = list => {
        const boxes = [];
        for (const scheme of list) {
            if(scheme.type === 'box'){
                boxes.push(scheme);
                const moreBoxes = this.getAllBoxes(scheme.children);
                for (const moreBox of moreBoxes) {
                    boxes.push(moreBox);
                }
            }
        }
        return boxes;
    }
    getObject = (objectKey) => {
        const boxes = [...this.state[this.state.type]];
        for (let i = 0; i < boxes.length; i++) {
            if(boxes[i].key === objectKey){
                return boxes[i];
            }
        }
        const children = [...this.state.children];
        for (let i = 0; i < children.length; i++) {
            if(children[i].key === objectKey){
                return children[i];
            }
        }
    }
    setObject = (objectKey, itemKey, value, newItem=null) => {
        const boxes = [...this.state[this.state.type]];
        for (let i = 0; i < boxes.length; i++) {
            if(boxes[i].key === objectKey){
                boxes[i][itemKey] = value;
                this.setState({
                    [this.state[this.state.type]]: boxes
                });
                break;
            }
        }
        const children = [...this.state.children];
        for (let i = 0; i < children.length; i++) {
            if(children[i].key === objectKey){
                children[i][itemKey] = value;
                if( newItem === null ){
                    this.setState({ children });
                }
                break;
            }
        }
        if( newItem !== null ){
            children.push(newItem);
            this.setState({ children });
        }
    }
    removeObject = (objectKey) => {
        this.removeObjectFromArray(objectKey);
        const boxes = [...this.state[this.state.type]];
        for (let i = 0; i < boxes.length; i++) {
            if(boxes[i].key === objectKey){
                boxes.splice(i, 1);
                this.setState({ [this.state[this.state.type]]: boxes });
                break;
            }
        }
        const children = [...this.state.children];
        for (let i = 0; i < children.length; i++) {
            if(children[i].key === objectKey){
                children.splice(i, 1);
                this.setState({ children });
                break;
            }
        }
    }
    removeObjectFromArray = (objectKey) => {
        const boxes = [...this.state[this.state.type]];
        for (let i = 0; i < boxes.length; i++) {
            for (let j = 0; j < boxes[i].children.length; j++) {
                if(boxes[i].children[j] === objectKey){
                    boxes[i].children.splice(j, 1);
                    this.setState({ [this.state[this.state.type]]: boxes });
                    break;
                }
            }
        }
        const children = [...this.state.children];
        for (let i = 0; i < children.length; i++) {
            for (let j = 0; j < children[i].children.length; j++) {
                if(children[i].children[j] === objectKey){
                    children[i].children.splice(j, 1);
                    this.setState({ children });
                    break;
                }
            }
        }
    }
    getComponents = () => {
        let page = 1;
        const components = [];
        const getComponentsAsync = async () => {
                try {
                    const res = await axios({
                        url: this.props.config.cdnUrl + 'components/getAll',
                        method: 'POST',
                        data: { token: this.props.config.token, page }
                    });
                    if(res.data.status === 200){
                        if(res.data.success){
                            const _components = [ ...res.data['components'] ];
                            for (const component of _components) {
                                components.push(component);
                            }
                            page += 1;
                            return getComponentsAsync();
                        }else{
                            this.setState({ components: [...components] });
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            };
        getComponentsAsync().then(r => r);
    }
    getComponent = (id) => {
        for (const component of this.state.components) {
            if(id === component.id){
                return component;
            }
        }
    }
    appendChild = (item) => {
        this.setState({
            children: [
                ...this.state.children,
                item
            ]
        });
    }
    appendItem = async (key, type='box') => {
        const item = { ...this.getObject(key) };
        const newItemKey = Math.random();
        if(type === 'box'){
            item.children.push(newItemKey);
            this.setObject(key, 'children', item.children, {
                key: newItemKey,
                type: 'box',
                title: `kutu - ${Math.floor(newItemKey * 10000).toString()}`,
                isRemovable: true,
                align: 'space-between',
                location: 'vertical',
                children: []
            });
        }else if(type.slice(0,4) === 'new-'){
            item.children.push(newItemKey);
            this.setObject(key, 'children', item.children, {
                key: newItemKey,
                type: 'component',
                componentType: type.slice(4),
                title: `bileşen - ${Math.floor(newItemKey * 10000).toString()}`,
                isRemovable: true,
                content: {},
                children: []
            });
        }else{
            item.children.push(newItemKey);
            const component = this.getComponent(type);
            this.setObject(key, 'children', item.children, {
                key: newItemKey,
                type: 'component',
                componentType: component.type,
                title: component.title,
                isRemovable: true,
                content: {
                    info: {
                        title: component.title,
                        type: component.type
                    },
                    [component.type]: { ...component.component }
                },
                children: []
            });
        }
    }
    getPagePerson = (children) => {
        let person = null;
        try {
            for (const item of children) {
                if(item.componentType === 'personBox' || item.componentType === 'pageInfoBox')
                    person = item.content[item.componentType].person;
            }
            return person;
        }catch (e) {
            return person;
        }
    }
    setChildrenSequence = (objectKey, currentSeq, wantedSeq) => {
        const object = { ...this.getObject(objectKey) };
        const child = object.children[currentSeq];
        object.children[currentSeq] = object.children[wantedSeq];
        object.children[wantedSeq] = child;
        this.setObject(objectKey, 'children', object.children);
    }
    createContentBox = ({
        key=null,
        type='box',
        componentType=null,
        title=null,
        isRemovable=true,
        align=null,
        location=null,
        children=[],
        appendNewBox=false,
    }) => {
        return(
            <div className={'scheme-content-box'} key={key} style={{
                flex: !appendNewBox && '1',
                marginBottom: !appendNewBox && '0'
            }}>
                <div className={'toolbar'}>
                    <Tag>{title}</Tag>
                    <div style={{flex: '1'}}/>
                    {type === 'box' && <Select value={location} style={{width: '130px'}} onChange={value => this.setObject(key, 'location', value)}>
                        <Option value={'vertical'}><ColumnHeightOutlined/><span style={{marginLeft: '.8rem'}}>Alt alta</span></Option>
                        <Option value={'horizontal'}><ColumnWidthOutlined/><span style={{marginLeft: '.8rem'}}>Yan yana</span></Option>
                    </Select>}
                    {type === 'box' && <Select value={align} style={{width: '140px'}} onChange={value => this.setObject(key, 'align', value)}>
                        <Option value={'space-between'}><MenuOutlined/><span style={{marginLeft: '.8rem'}}>Dengeli</span></Option>
                        <Option value={'left'}><AlignLeftOutlined/><span style={{marginLeft: '.8rem'}}>Sola hizala</span></Option>
                        <Option value={'right'}><AlignRightOutlined/><span style={{marginLeft: '.8rem'}}>Sağa hizala</span></Option>
                        <Option value={'center'}><AlignCenterOutlined/><span style={{marginLeft: '.8rem'}}>Ortala</span></Option>
                    </Select>}
                    {isRemovable && <Button type={'text'} icon={<DeleteOutlined/>} onClick={() => this.removeObject(key)}/>}
                </div>
                <div className={'box-and-components'}>
                    {type === 'box' ? children.map((child) => {
                        child = this.getObject(child);
                        return (
                            <div key={child.key} style={{ display: 'flex', marginBottom: '.5rem' }}>
                                <div style={{
                                    width: '.5rem',
                                    backgroundColor: '#dddddd',
                                    marginRight: '1rem'
                                }}/>
                                {this.createContentBox({ ...child })}
                            </div>
                        )
                    }) : <EditableComponent
                        componentType={componentType}
                        handleChange={state => this.setObject(key, 'content', state)}
                        content={this.getObject(key).content}
                    />}
                </div>
                {type === 'box' && <div className={'append-area'}>
                    <Button onClick={() => this.appendItem(key)}>Yeni kutu ekle</Button>
                    <Select style={{ width: 200 }} value={'Bir bileşen ekle'} onChange={value => this.appendItem(key, value).then(r => r)}>
                        <OptGroup label={'Yeni'}>
                            {Object.keys(this.props.config.componentTypes).map(key => {
                                if(key === 'person'){
                                    return(<Option key={'personBox'} value={'new-personBox'}>Kişi Kutusu</Option>);
                                }else{
                                    return(<Option key={key} value={'new-'+key}>{this.props.config.componentTypes[key]}</Option>);
                                }
                            })}
                        </OptGroup>
                        <OptGroup label={'Mevcut'}>
                            {this.state.components.map(component => {
                                return(<Option key={component.id} value={component.id}>{component.title}</Option>);
                            })}
                        </OptGroup>
                    </Select>
                </div>}
            </div>
        );
    }
    componentDidMount() {
        setTimeout(() => this.getComponents(), 100);
    }
}

export default SchemeEditor;
