import {Modal, notification, Pagination, Spin, Upload} from 'antd';
import axios from 'axios';
import {createImageList, setCurrentPage, setLoading} from '../../store/actions/images';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import ImageUploader from './imageUploader';

const { confirm } =  Modal;

class ImageList extends ImageUploader{
    state = {
        loading: true,
        viewImageUrl: '',
        viewImageVisibility: false
    };
    handlePreview = (file) => {
        if(this.props.hasOwnProperty('selectImageFnc')){
            this.props.selectImageFnc(file);
        }else{
            this.setState({
                viewImageVisibility: true,
                viewImageUrl: file.url
            });
        }
    }
    removeGetCurrentPage = () => {
        const total = this.props.images.gallery.total.length - 1;
        if(Math.ceil(total/21) === this.props.images.gallery.currentPage){
            return this.props.images.gallery.currentPage;
        }else{
            return this.props.images.gallery.currentPage - 1;
        }
    }
    loadingFalse = () => {
        setTimeout(() => {
                this.props.dispatch(setLoading(false));
            }, 500
        );
    }
    downloadPage = (page) => {
        this.props.dispatch(setLoading(true));
        this.setState({current: page});
        const total = [...this.props.images.gallery.total];
        const requestedPage = total.slice(((page-1)*21),(page*21));
        let isEmpty = true;
        for (const img of requestedPage) {
            if(img === null){
                axios({
                    url: this.props.config.cdnUrl + 'images/getAll',
                    method: 'POST',
                    data: {
                        token: this.props.config.token, page
                    }
                }).then(r => {
                    if(r.data.status === 200){
                        if(r.data.success){
                            this.props.dispatch(setCurrentPage(page, r.data.images.map((img, index) => {
                                return {
                                    uid: index.toString(),
                                    url: img
                                }
                            })));
                            this.loadingFalse();
                        }else{
                            this.failedToLoadResources();
                        }
                    }else{
                        this.failedToLoadResources();
                    }
                })
                isEmpty = false;
                break;
            }
        }
        if(isEmpty){
            this.props.dispatch(setCurrentPage(page,requestedPage));
            this.loadingFalse();
        }
    }
    removeImage = (img) => {
        confirm({
            title: 'Bu fotoğrafı silmek istediğinize emin misiniz?',
            icon: <ExclamationCircleOutlined/>,
            content: 'Bu işlem geri alınamayacak ve eğer bu fotoğrafı sitenizde kullanıyorsanız birtakım aksaklıklara sebep olacaktır.',
            okText: 'Eminim',
            cancelText: 'İptal',
            onOk: () => {
                const url = new URL(img.url);
                const imageName = url.searchParams.get('img');
                axios({
                    url: this.props.config.cdnUrl + 'images/remove',
                    method: 'POST',
                    data: {
                        token: this.props.config.token,
                        img: imageName
                    }
                }).then(r => {
                    if(r.data.status === 200){
                        if(r.data.success){
                            notification.success({
                                message: 'İşlem başarılı!',
                                description: <span><i>{imageName}</i> adlı dosyanız başarıyla silindi!</span>
                            })
                            let currentPage = this.removeGetCurrentPage();
                            currentPage = currentPage === 0 ? 1 : currentPage;
                            this.firstDownload(currentPage);
                        }else{
                            this.failedToLoadResources();
                        }
                    }else{
                        this.failedToLoadResources();
                    }
                })
            }
        });
    }
    componentDidMount() {
        setTimeout(() => this.firstDownload(), 100);
    }
    render() {
        const imageCount = this.props.images.gallery.total.length;
        let imgName;
        try{
            const url = new URL(this.state.viewImageUrl);
            imgName = url.searchParams.get('img');
        }catch (e) {
            imgName = '';
        }
        return (
            <div className={'image-list'}>
                <div className={'list'}>
                    <div className={'list-loading'} style={{display: this.props.images.gallery.loading ? 'flex' : 'none'}}><Spin/></div>
                    <Upload
                        listType={'picture-card'}
                        fileList={this.props.images.gallery.current}
                        beforeUpload={() => false}
                        onRemove={this.removeImage}
                        onPreview={this.handlePreview}
                    />
                    <Modal
                        visible={this.state.viewImageVisibility}
                        title={imgName}
                        onCancel={() => {
                            this.setState({
                                viewImageVisibility: false
                            })
                        }}
                        footer={[]}
                    >
                        <img
                            src={this.state.viewImageUrl}
                            alt={'halis yücel'}
                            style={{ width: '100%' }}
                        />
                    </Modal>
                </div>
                <div className={'pagination'}>
                    <Pagination
                        defaultCurrent={this.props.images.gallery.currentPage}
                        total={imageCount === 0 ? 1 : imageCount}
                        pageSize={21}
                        responsive={true}
                        onChange={this.downloadPage}
                        showSizeChanger={false}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.config,
        images: state.images
    }
}
const mapDispatchToProps = (dispatch) => ({
    setLoading,
    createImageList,
    setCurrentPage,
    dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(ImageList);
