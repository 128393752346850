import {Button, Modal, notification, PageHeader, Popconfirm, Space, Table} from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import '../../stylesheets/page-components/page-components.scss';
import '../../stylesheets/page-components/page-components-list.scss';
import {setPopup} from '../../store/actions/images';
import {PictureOutlined} from '@ant-design/icons';
import PageComponents from '../common-use/pageComponents';
import {setConfig} from '../../store/actions/config';
import ReactJson from 'react-json-view'

class PageComponentsList extends PageComponents{
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            jsonView: {},
            jsonViewVisible: false,
            currentEditComponentId: null,
            modalVisible: false,
            pagination: {
                current: 1,
                total: 10,
                pageSize: 10,
            },
            columns: [
                {
                    title: 'Bileşen ismi',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: 'Bileşen tipi',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: 'Olaylar',
                    dataIndex: 'events',
                    key: 'events',
                    width: '200px',
                    render: component => (
                        <Space size={'middle'}>
                            <Button
                                onClick={() => this.editComponent(
                                    component.id,
                                    component.type,
                                    component.component
                                )}
                                key={'1'}
                                size={'small'}
                            >
                                Bileşeni Düzenle
                            </Button>
                            <Button
                                onClick={() => this.showJSON(component)}
                                key={'1'}
                                size={'small'}
                            >
                                JSON
                            </Button>
                            <Popconfirm
                                title={'Bu bileşeni silmek istediğinize emin misiniz?'}
                                okText={'Evet'}
                                cancelText={'Hayır'}
                                placement={'topRight'}
                                onConfirm={() => this.deleteComponent(component.id)}
                                key={'3'}
                            >
                                <Button
                                    type={'danger'}
                                    size={'small'}
                                >
                                    Sil
                                </Button>
                            </Popconfirm>
                        </Space>
                    ),
                }
            ],
            data: []
        }
    }
    error = () => {
        notification.error({
            message: 'Bir Hata Oluştu!',
            description: 'database\'ye bağlanılamadı',
        });
        this.setState({ loading: false });
    }
    showJSON = (component) => {
        this.setState({
            jsonView: component,
            jsonViewVisible: true,
        })
    }
    deleteComponent = id => {
        this.setState({loading: true});
        axios({
            url: this.props.config.cdnUrl + 'components/remove',
            method: 'POST',
            data: {
                token: this.props.config.token,
                componentId: id
            }
        })
            .then(res => {
                if(res.data.status === 200){
                    if(res.data.success){
                        notification.success({
                            message: 'İşlem Başarılı',
                            description: 'Bileşen başarıyla silindi.',
                        });
                        return this.getComponents(this.state.pagination.current);
                    }else{
                        this.error();
                    }
                }else{
                    this.error();
                }
            })
            .catch(() => {
                this.error();
            })
    }
    editComponent = (id, type, component) => {
        this.setState({
            info: { ...this.state.info, type },
            modalVisible: true,
            currentEditComponentId: id,
            [type]: {...component}
        });
    }
    getComponents = (page = 1) => {
        this.setState({loading: true});
        axios({
            url: this.props.config.cdnUrl + 'components/getAll',
            method: 'POST',
            data: {
                token: this.props.config.token,
                page: page
            }
        })
            .then(res => {
                if(res.data.status === 200){
                    if(res.data.success){
                        const components = res.data['components'].map(component => {
                            return {
                                id: component['id'],
                                key: component['id'],
                                type: this.props.config.componentTypes[component['type']],
                                title: component['title'],
                                events: {...component}
                            };
                        });
                        this.setState({
                            loading: false,
                            data: components,
                            pagination: {
                                ...this.state.pagination,
                                total: res.data.total
                            }
                        });
                    }else{
                        const current = this.state.pagination.current;
                        if(res.data['msg'] === 'page is not exist' && current > 1){
                            this.setState({
                                pagination: {
                                    ...this.state.pagination,
                                    current: current - 1
                                }
                            });
                            return this.getComponents(current - 1);
                        }else{
                            this.error();
                        }
                    }
                }else{
                    this.error();
                }
            })
            .catch(() => {
                this.error();
            })
    }
    changeCurrentPage = page => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                current: page
            }
        });
        this.getComponents(page);
    }
    saveComponent = () => {
        const check = this.componentPreCheck();
        if(check[0]){
            this.setState({ loading: true });
            axios({
                url: this.props.config.cdnUrl + 'components/edit',
                method: 'POST',
                data: {
                    token: this.props.config.token,
                    id: this.state.currentEditComponentId,
                    component: JSON.stringify(check[1])
                }
            })
                .then(res => {
                    if(res.data.status === 200){
                        if(res.data.success){
                            this.setState({ loading: false, modalVisible: false });
                            notification.success({
                                message: 'Bileşen başarıyla düzenlendi!',
                                description: 'Bileşen düzenleme işlemi başarıyla gerçekleştirildi'
                            });
                            this.getComponents(this.state.pagination.current);
                        }else{
                            this.setState({ loading: false });
                            notification.error({
                                message: 'Bileşen düzenlenemedi!',
                                description: 'Böyle bir bileşen bulunamadı!'
                            });
                        }
                    }
                    else{
                        this.setState({ loading: false });
                        notification.error({
                            message: 'Bileşen düzenlenemedi!',
                            description: 'Teknik bir sorundan dolayı bileşen düzenlenemedi'
                        });
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.setState({ loading: false });
                    notification.error({
                        message: 'Bileşen düzenlenemedi!',
                        description: 'Teknik bir sorundan dolayı bileşen düzenlenemedi'
                    });
                })
        }else{
            notification.warning({
                message: 'Geçersiz bileşen',
                description: check[1]
            });
        }
    }
    componentDidMount() {
        this.setState({
            pagination: {
                ...this.state.pagination,
                onChange: this.changeCurrentPage
            }
        })
        setTimeout(() => {
            this.getComponents();
            this.getPersons();
            this.getContentListingTitles();
        }, 100);
    }
    render() {
        return (
            <div className={'page-components-list'}>
                <PageHeader
                    className={'page-header'}
                    title={'Sayfa bileşenleri listesi'}
                />
                <div className={'page-content'}>
                    <Table
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        size={'middle'}
                        bordered={true}
                        columns={this.state.columns}
                        dataSource={this.state.data}
                    />
                </div>
                <Modal
                    style={{ top: 30 }}
                    width={'min(98vw,52rem)'}
                    visible={this.state.modalVisible}
                    title={'Bileşeni düzenle'}
                    onCancel={() => this.setState({modalVisible: false})}
                    footer={[
                        <div key={'footer'} style={{ width: '100%', display: 'flex', flexWrap: 'wrap'}}>
                            <div style={{flex: '1'}}/>
                            <Button onClick={() => this.setState({modalVisible: false})} style={{
                                marginRight: '.5rem',
                                marginTop: '.3rem'
                            }}>İptal</Button>,
                            <Button type={'primary'} onClick={this.saveComponent} loading={this.state.loading} style={{
                                marginLeft: '0',
                                marginTop: '.3rem'
                            }}>Düzenlemeyi kaydet</Button>
                        </div>
                    ]}
                >
                    <div className={'component-edit-area'}>
                        {this.createComponent(this.state.info.type)}
                    </div>
                </Modal>
                <Modal
                    width={'min(98vw,52rem)'}
                    visible={this.state.jsonViewVisible}
                    title={'JSON'}
                    onCancel={() => this.setState({ jsonViewVisible: false })}
                    footer={[]}
                >
                    <ReactJson src={this.state.jsonView}/>
                </Modal>
                <Button
                    className={'select-image-button'}
                    icon={<PictureOutlined/>}
                    onClick={() => this.props.dispatch(setPopup({open: true}))}
                >
                    Görsel Seç
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.config
    }
}

const mapDispatchToProps = (dispatch) => ({
    setConfig,
    setPopup,
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(PageComponentsList);