import React, {Component} from 'react';
import {Button, Input, notification, PageHeader} from 'antd';
import '../../stylesheets/redirects/redirects.scss';
import {DeleteOutlined} from '@ant-design/icons';
import axios from 'axios';
import {connect} from 'react-redux';

class Redirects extends Component {
    state = {
        data: []
    };
    handleChange = (value, index1, index2) => {
        const oldData = [...this.state.data];
        oldData[index1][index2] = value;
        this.setState({ data: oldData})
    }
    removeItem = index => {
        const oldData = [...this.state.data];
        oldData.splice(index,1)
        this.setState({ data: oldData})
    }
    appendItem = () => {
        this.setState({
            data: [...this.state.data, [null, null]]
        })
    }
    save = () => {
        let isSave = true;
        for (const item of this.state.data) {
            if(item[0] === null || item[0] === undefined || item[0]?.trim() === ''){
                notification.error({
                    message: 'Değişiklikler kaydedilemedi!',
                    description: 'Yönlendirmelerde eski yolu boş bırakamazsın!',
                });
                isSave = false;
                break;
            }
            else if(item[1] === null || item[1] === undefined || item[1]?.trim() === ''){
                notification.error({
                    message: 'Değişiklikler kaydedilemedi!',
                    description: 'Yönlendirmelerde yeni yolu boş bırakamazsın!',
                });
                isSave = false;
                break;
            }
        }
        if(isSave){
            axios({
                url: this.props.config.cdnUrl + 'config/editRedirects',
                method: 'POST',
                data: {
                    token: this.props.config.token,
                    redirects: JSON.stringify(this.state.data)
                }
            })
                .then(res => {
                    if(res.data.hasOwnProperty('success')){
                        if(res.data.success){
                            notification.success({
                                message: 'İşlem başarılı',
                                description: 'Değişiklikler başarıyla kaydedildi.',
                            });
                            this.getData();
                        }else{
                            notification.error({
                                message: 'İşlem başarısız!',
                                description: 'Database\'ye bağlanılamadı!',
                            });
                        }
                    }else{
                        notification.error({
                            message: 'İşlem başarısız!',
                            description: 'Database\'ye bağlanılamadı!',
                        });
                    }
                })
                .catch(() => {
                    notification.error({
                        message: 'İşlem başarısız!',
                        description: 'Database\'ye bağlanılamadı!',
                    });
                })
        }
    }
    getData = () => {
        axios({
            url: this.props.config.cdnUrl + 'config/getRedirects',
            method: 'POST'
        })
            .then(res => {
                if(res.data.hasOwnProperty('success')){
                    if(res.data.success){
                        this.setState({data: res.data.config.list})
                    }
                }
            })
    }
    componentDidMount() {
        setTimeout(() => this.getData(), 100);
    }
    render() {
        return (
            <div className={'redirects'}>
                <PageHeader
                    className={'page-header'}
                    title={'Yönlendirmeler'}
                />
                <div className={'page-content'}>
                    {this.state.data.map((item, index) => {
                        return(<div key={index} className={'redirect-item'}>
                            <Input
                                value={item[0]}
                                addonBefore={'Eski yol'}
                                spellCheck={false}
                                onChange={e => this.handleChange(e.target.value, index, 0)}
                            />
                            <Input
                                value={item[1]}
                                addonBefore={'Yeni yol'}
                                spellCheck={false}
                                onChange={e => this.handleChange(e.target.value, index, 1)}
                            />
                            <Button
                                icon={<DeleteOutlined/>}
                                style={{ width: '4rem'}}
                                onClick={() => this.removeItem(index)}
                            />
                        </div>);
                    })}
                    <div style={{ display: 'flex' }}>
                        <Button
                            style={{ width: '12rem' }}
                            onClick={this.appendItem}
                        >
                            Yeni bir yönlendirme ekle
                        </Button>
                        <div style={{ flex: '1' }}/>
                        <Button
                            type={'primary'}
                            onClick={this.save}
                        >
                            Kaydet
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {config: state.config}
}
export default connect(mapStateToProps, null)(Redirects);

