import React, {Component} from 'react';
import {Button, Divider, Input, notification, PageHeader, Select, Space, Typography} from 'antd';
import {connect} from 'react-redux';
import '../../stylesheets/settings/settings.scss';
import {
    DeleteOutlined, InstagramOutlined, PlusOutlined,
    SendOutlined, TwitterOutlined, YoutubeOutlined
} from '@ant-design/icons';
import {setPopup} from '../../store/actions/images';
import axios from 'axios';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

class Settings extends Component {
    state = {
        socialMedia: {
            twitter: null,
            instagram: null,
            youtube: null,
            telegram: null
        },
        contact: {
            email: null,
            phoneNumber: null,
            address: null,
            about: null
        },
        menu: [
            {
                key: Math.random(),
                type: 'link',
                title: 'Ana sayfa',
                url: 'https://ankaratibbiyeliler.org.tr',
                cascader: []
            }
        ]
    };

    error = (err='source') => {
        if(err === 'source'){
            notification.error({
                message: 'İşleminiz gerçekleştirilemedi!',
                description: 'Kaynaklar yüklenirken bir hata oluştu.'
            });
        }else{
            notification.error({
                message: 'İşleminiz gerçekleştirilemedi!',
                description: 'Ayarları düzenleme işlemi başarısız oldu.'
            });
        }
    }

    setHeader = (itemKey, propKey, value) => {
        const menuItems = [...this.state.menu].map(menuItem => {
            if(menuItem.key === itemKey){
                menuItem[propKey] = value;
            }else{
                menuItem.cascader = menuItem.cascader.map(cascaderItem => {
                    if(cascaderItem.key === itemKey)
                        cascaderItem[propKey] = value;
                    return cascaderItem;
                })
            }
            return menuItem;
        })
        this.setState({ menu: menuItems });
    }

    setHeaderAppend = (obj) => {
        const menu = [...this.state.menu];
        menu.push(obj);
        this.setState({ menu });
    }

    setHeaderChangeOrder = (index1, index2) => {
        const menu = [...this.state.menu];
        const obj = { ...menu[index1] };
        menu[index1] = menu[index2];
        menu[index2] = obj;
        this.setState({ menu });
    }

    setHeaderRemove = (itemKey) => {
        let menu = [...this.state.menu].filter(e => e.key !== itemKey);
        menu = menu.map(menuItem => {
            menuItem.cascader = menuItem.cascader.filter(cascaderItem => cascaderItem.key !== itemKey)
            return menuItem;
        })
        this.setState({ menu });
    }

    editConfig = () => {
        axios({
            url: this.props.config.cdnUrl + 'config/edit',
            method: 'POST',
            data: {
                token: this.props.config.token,
                config: JSON.stringify({ ...this.state })
            }
        })
            .then(res => {
                if(res.data.status === 200){
                    if(res.data.success){
                        this.getConfig();
                        notification.success({
                            message: 'İşlem başarılı',
                            description: 'Ayarları düzenleme işlemi başarıyla gerçekleştirildi.'
                        })
                    }else{
                        this.error('edit');
                    }
                }else{
                    this.error('edit');
                }
            })
            .catch(() => {
                this.error('edit');
            })
    }

    getConfig = () => {
        axios({
            url: this.props.config.cdnUrl + 'config/get',
            method: 'POST',
            data: {
                token: this.props.config.token
            }
        })
            .then(res => {
                if(res.data.status === 200){
                    if(res.data.success){
                        this.setState({ ...res.data.config });
                    }else{
                        this.error();
                    }
                }else{
                    this.error();
                }
            })
            .catch(() => {
                this.error();
            })
    }

    appendCascader = (itemKey) => {
        const menuItems = [...this.state.menu];
        for (let i = 0; i < menuItems.length; i++) {
            if(menuItems[i].key === itemKey){
                menuItems[i].cascader.push({
                    key: Math.random(),
                    type: 'cascader-item',
                    title: null,
                    url: null,
                    cascader: []
                });
                break;
            }
        }
        this.setState({ menu: menuItems });
    }

    createMenuItem = (item) => {
        return(
            <div className={'menu-item type-' + item.type} key={item.key}>
                <div className={'toolbar'}>
                    <Select
                        defaultValue={item.type}
                        style={{ width: 180, display: item.type === 'cascader-item' && 'none' }}
                        onChange={type => this.setHeader(item.key, 'type', type)}
                    >
                        <Option value={'link'}>Link</Option>
                        <Option value={'cascader'}>İç Menü</Option>
                    </Select>
                    <div style={{flex: '1'}}/>
                    {item.type === 'cascader' && <Button
                        icon={<PlusOutlined/>}
                        onClick={() => this.appendCascader(item.key)}
                    />}
                    {this.state.menu.length !== 1 && <Button
                        icon={<DeleteOutlined/>}
                        onClick={() => this.setHeaderRemove(item.key)}
                    />}
                </div>
                <Input
                    spellCheck={false}
                    addonBefore={'Başlık'}
                    value={item.title}
                    onChange={e => this.setHeader(item.key, 'title', e.target.value)}
                />
                <Input
                    style={{ display: item.type === 'cascader' && 'none' }}
                    spellCheck={false}
                    addonBefore={'URL'}
                    value={item.url}
                    onChange={e => this.setHeader(item.key, 'url', e.target.value)}
                />
                {item.type === 'cascader' && item.cascader.map(cascaderItem => {
                    return(this.createMenuItem(cascaderItem));
                })}
            </div>
        );
    }

    componentDidMount() {
        setTimeout(() => this.getConfig(), 100);
    }

    render() {
        return (
            <div className={'settings'}>
                <PageHeader
                    className={'page-header'}
                    title={'Ayarlar'}
                />
                <div className={'page-content'}>
                    <Title level={4}>Sosyal medya hesapları</Title>
                    <div className={'social-media'}>
                        <Input
                            spellCheck={false}
                            value={this.state.socialMedia.twitter}
                            addonBefore={<Space><TwitterOutlined/>Twitter</Space>}
                            placeholder={'https://twitter.com/sayfaniz'}
                            onChange={e => this.setState({ socialMedia: { ...this.state.socialMedia, twitter: e.target.value } })}
                        />
                        <Input
                            spellCheck={false}
                            value={this.state.socialMedia.instagram}
                            addonBefore={<Space><InstagramOutlined/>Instagram</Space>}
                            placeholder={'https://instagram.com/sayfaniz'}
                            onChange={e => this.setState({ socialMedia: { ...this.state.socialMedia, instagram: e.target.value } })}
                        />
                        <Input
                            spellCheck={false}
                            value={this.state.socialMedia.youtube}
                            addonBefore={<Space><YoutubeOutlined/>Youtube</Space>}
                            placeholder={'https://youtube.com/kanaliniz'}
                            onChange={e => this.setState({ socialMedia: { ...this.state.socialMedia, youtube: e.target.value } })}
                        />
                        <Input
                            spellCheck={false}
                            value={this.state.socialMedia.telegram}
                            addonBefore={<Space><SendOutlined/>Telegram</Space>}
                            placeholder={'https://telegram.com/kanaliniz'}
                            onChange={e => this.setState({ socialMedia: { ...this.state.socialMedia, telegram: e.target.value } })}
                        />
                    </div>
                    <Title level={4}>İletişim</Title>
                    <div className={'contact'}>
                        <Input
                            addonBefore={'E-posta adresi'}
                            spellCheck={false}
                            value={this.state.contact.email}
                            onChange={e => this.setState({ contact: { ...this.state.contact, email: e.target.value } })}
                        />
                        <Input
                            addonBefore={'Telefon numarası'}
                            spellCheck={false}
                            value={this.state.contact.phoneNumber}
                            onChange={e => this.setState({ contact: { ...this.state.contact, phoneNumber: e.target.value } })}
                        />
                        <TextArea
                            placeholder={'Adresiniz'}
                            rows={2}
                            spellCheck={false}
                            value={this.state.contact.address}
                            onChange={e => this.setState({ contact: { ...this.state.contact, address: e.target.value } })}
                        />
                        <TextArea
                            placeholder={'Sizi açıklayan bir yazı'}
                            rows={5}
                            spellCheck={false}
                            value={this.state.contact.about}
                            onChange={e => this.setState({ contact: { ...this.state.contact, about: e.target.value } })}
                        />
                    </div>
                    <Title level={4}>Sayfa menüsü</Title>
                    <div className={'page-menu'}>
                        {this.state.menu.map((item, index) => this.createMenuItem(item, index))}
                        <Button
                            onClick={() => this.setHeaderAppend({
                                key: Math.random(),
                                type: 'link',
                                title: null,
                                url: null,
                                cascader: []
                            })}
                        >
                            Yeni başlık ekle
                        </Button>
                    </div>
                    <Divider/>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <div style={{ flex: '1' }}/>
                        <Button onClick={this.editConfig} type={'primary'}>Değişiklikleri kaydet</Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {config: state.config}
}
const mapDispatchToProps = (dispatch) => ({
    setPopup,
    dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
