import {Button, Modal, notification, PageHeader, Popconfirm, Space, Table} from 'antd';
import axios from 'axios';
import {PictureOutlined} from '@ant-design/icons';
import {setPopup} from '../../store/actions/images';
import SchemeEditor from '../common-use/schemeEditor';
import { connect } from 'react-redux';
import '../../stylesheets/pages/schemeList.scss';

class SchemeList extends SchemeEditor {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            schemeEditLoading: false,
            loading: false,
            currentEditSchemeId: null,
            modalVisible: false,
            pagination: {
                current: 1,
                total: 10,
                pageSize: 10,
            },
            columns: [
                {
                    title: 'Şablon ismi',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Şablonun linkde görünme şekli',
                    dataIndex: 'nameForURL',
                    key: 'nameForURL',
                },
                {
                    title: 'Olaylar',
                    dataIndex: 'events',
                    key: 'events',
                    width: '200px',
                    render: scheme => (
                        <Space size={'middle'}>
                            <Button
                                onClick={() => this.editScheme(scheme)}
                                key={'1'}
                                size={'small'}
                            >
                                Şablonu Düzenle
                            </Button>
                            <Popconfirm
                                title={'Bu şablonu silmek istediğinize emin misiniz?'}
                                okText={'Evet'}
                                cancelText={'Hayır'}
                                placement={'topRight'}
                                onConfirm={() => this.deleteScheme(scheme.id)}
                                key={'3'}
                            >
                                <Button
                                    type={'danger'}
                                    size={'small'}
                                >
                                    Sil
                                </Button>
                            </Popconfirm>
                        </Space>
                    ),
                }
            ],
            data: []
        }
    }
    error = () => {
        notification.error({
            message: 'Bir Hata Oluştu!',
            description: 'database\'ye bağlanılamadı',
        });
        this.setState({ loading: false });
    }
    deleteScheme = id => {
        this.setState({ loading: true });
        axios({
            url: this.props.config.cdnUrl + 'schemes/remove',
            method: 'POST',
            data: { token: this.props.config.token, id }
        })
            .then(res => {
                if(res.data.status === 200){
                    if(res.data.success){
                        notification.success({
                            message: 'İşlem Başarılı',
                            description: 'Şablon başarıyla silindi.',
                        });
                        return this.getSchemes(this.state.pagination.current);
                    }else{
                        this.error();
                    }
                }else{
                    this.error();
                }
            })
            .catch(() => {
                this.error();
            })
    }
    editScheme = (scheme) => {
        this.setState({
            modalVisible: true,
            currentEditComponentId: scheme.id,
            info: {
                schemeName: scheme['name'],
                schemeNameForURL: scheme['nameForURL'],
                schemeImage: scheme['metaImage'],
                schemeDescription: scheme['description'],
                coverImage: scheme['coverImage'],
                coverImageInfo: scheme['coverImageInfo'],
                tags: scheme['tags']
            },
            type: scheme.type,
            children: [ ...scheme.content.children ],
            [scheme.type]: [ ...scheme.content.scheme ]
        });
    }
    getSchemes = (page = 1) => {
        this.setState({ loading: true });
        axios({
            url: this.props.config.cdnUrl + 'schemes/getAll',
            method: 'POST',
            data: {
                token: this.props.config.token,
                page: page
            }
        })
            .then(res => {
                if(res.data.status === 200){
                    if(res.data.success){
                        const schemes = res.data['schemes'].map(scheme => {
                            return {
                                id: scheme['id'],
                                key: scheme['id'],
                                type: scheme['type'],
                                nameForURL: scheme['nameForURL'],
                                name: scheme['name'],
                                events: { ...scheme }
                            };
                        });
                        this.setState({
                            loading: false,
                            data: schemes,
                            pagination: {
                                ...this.state.pagination,
                                total: res.data.total
                            }
                        });
                    }else{
                        const current = this.state.pagination.current;
                        if(res.data['msg'] === 'page is not exist' && current > 1){
                            this.setState({
                                pagination: {
                                    ...this.state.pagination,
                                    current: current - 1
                                }
                            });
                            return this.getSchemes(current - 1);
                        }else{
                            this.error();
                        }
                    }
                }else{
                    this.error();
                }
            })
            .catch(() => {
                this.error();
            })
    }
    changeCurrentPage = page => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                current: page
            }
        });
        this.getSchemes(page);
    }
    saveScheme = () => {
        const [error, info] = this.checkInfo(this.state.info);
        if(error){
            notification.error({
                message: 'Şablon kaydedilemedi!',
                description: error
            });
        }else{
            this.setState({ schemeEditLoading: true });
            const content = {
                type: this.state.type,
                info: info,
                content: {
                    scheme: this.state[this.state.type],
                    children: this.state.children
                }
            }
            axios({
                url: this.props.config.cdnUrl + 'schemes/edit',
                method: 'POST',
                data: {
                    token: this.props.config.token,
                    id: this.state.currentEditComponentId,
                    content: JSON.stringify(content)
                }
            })
                .then(res => {
                    if(res.data.status === 200){
                        if(res.data.success){
                            notification.success({
                                message: 'Şablon kaydedildi!',
                                description: 'Şablonunuz başarıyla düzenlendi!'
                            });
                            this.getSchemes(this.state.pagination.current)
                        }else{
                            notification.error({
                                message: 'Şablon kaydedilemedi!',
                                description: 'Teknik bir aksaklıktan dolayı şablon kaydedilemedi!'
                            });
                        }
                    }
                    this.setState({ schemeEditLoading: false });
                })
                .catch(() => {
                    this.setState({ schemeEditLoading: false });
                    notification.error({
                        message: 'Şablon kaydedilemedi!',
                        description: 'Teknik bir aksaklıktan dolayı şablon kaydedilemedi!'
                    });
                });
        }
    }
    componentDidMount() {
        this.setState({
            pagination: {
                ...this.state.pagination,
                onChange: this.changeCurrentPage
            }
        })
        setTimeout(() => {
            this.getSchemes();
            this.getComponents();
        }, 100);
    }
    render() {
        return (
            <div className={'scheme-list'}>
                <PageHeader
                    className={'page-header'}
                    title={'Şablon listesi'}
                />
                <div className={'page-content'}>
                    <Table
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        size={'middle'}
                        bordered={true}
                        columns={this.state.columns}
                        dataSource={this.state.data}
                    />
                </div>
                <Modal
                    style={{ top: 30 }}
                    width={'min(98vw,80rem)'}
                    visible={this.state.modalVisible}
                    title={'Şablonu düzenle'}
                    onCancel={() => this.setState({modalVisible: false})}
                    footer={[
                        <div key={'footer'} style={{ width: '100%', display: 'flex', flexWrap: 'wrap'}}>
                            <div style={{flex: '1'}}/>
                            <Button onClick={() => this.setState({modalVisible: false})} style={{
                                marginRight: '.5rem',
                                marginTop: '.3rem'
                            }}>İptal</Button>,
                            <Button type={'primary'} onClick={this.saveScheme} loading={this.state.pageEditLoading} style={{
                                marginLeft: '0',
                                marginTop: '.3rem'
                            }}>Düzenlemeyi kaydet</Button>
                        </div>
                    ]}
                >
                    <div className={'scheme-edit-area'}>
                        {this.createSchemeInfoWithoutName()}
                        {this.createContent()}
                    </div>
                </Modal>
                <Button
                    className={'select-image-button'}
                    icon={<PictureOutlined/>}
                    onClick={() => this.props.dispatch(setPopup({open: true}))}
                >
                    Görsel Seç
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { config: state.config }
}
const mapDispatchToProps = (dispatch) => ({
    setPopup,
    dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(SchemeList);
