import {Button, message, PageHeader, Upload, Modal} from 'antd';
import '../../stylesheets/gallery/gallery.scss';
import {connect} from 'react-redux';
import {InboxOutlined} from '@ant-design/icons';
import ImageList from '../common-use/imageList';
import {createImageList, setCurrentPage, setLoading} from '../../store/actions/images';
import ImageUploader from '../common-use/imageUploader';

const { Dragger } = Upload;

class Gallery extends ImageUploader{
    state = {
        uploadModalVisibility: false
    };
    setVisibilityUploadModal = (visibility) => {
        this.setState({uploadModalVisibility: visibility});
    }
    uploadModalOnChange = (info) => {
        const { status } = info.file;
        if (status === 'uploading') {
            message.loading({content: <small><i>{info.file.name}</i> adlı dosyanız yükleniyor...</small>, key: info.file.uid, duration: 30}).then(r =>r);
        }
        if (status === 'done') {
            message.success({content: <small><i>{info.file.name}</i> adlı dosyanız başarıyla yüklendi.</small>, key: info.file.uid, duration: 4}).then(r =>r);
            this.firstDownload(this.props.images.gallery.currentPage);
        } else if (status === 'error') {
            message.error({content: <small><i>{info.file.name}</i> adlı dosyanız yüklenemedi!</small>, key: info.file.uid, duration: 4}).then(r =>r);
        }
    }
    render() {
        return (
            <div className={'gallery-page'}>
                <PageHeader
                    className={'page-header'}
                    title={'Galeri'}
                    extra={[
                        <Button key={'1'} onClick={() => this.setVisibilityUploadModal(true)}>
                            Yeni Fotoğraf/lar Ekle
                        </Button>
                    ]}
                />
                <ImageList/>
                <Modal
                    visible={this.state.uploadModalVisibility}
                    title={'Yeni Fotoğraf/lar Ekle'}
                    onCancel={() => this.setVisibilityUploadModal(false)}
                    footer={[]}
                >
                    <Dragger
                        multiple={true}
                        action={this.props.config.cdnUrl + 'images/set'}
                        onChange={this.uploadModalOnChange}
                        beforeUpload={this.fileCheck}
                        showUploadList={false}
                        method={'POST'}
                        data={{token: this.props.config.token}}
                    >
                        <p className={'ant-upload-drag-icon'}>
                            <InboxOutlined />
                        </p>
                        <p className={'ant-upload-text'}>
                            Dosyalarınız yüklemek için bu alana tıklayın veya sürükleyin.
                        </p>
                        <p className={'ant-upload-hint'}>
                            Yalnızca .jpeg .jpg ve .png uzantıları kabul edilebilir ve dosyalarınız 3mb'den büyük olamaz.
                        </p>
                    </Dragger>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.config,
        images: state.images
    }
}
const mapDispatchToProps = (dispatch) => ({
    setLoading,
    createImageList,
    setCurrentPage,
    dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
